import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExchangeCertDialogComponent } from 'src/app/components';
import { StoreDialogComponent } from 'src/app/components/store-dialog/store-dialog.component';
import { DialogVideoComponent } from '../../../components/dialog-video/dialog-video.component';
//import { StoreDialog2Component } from '../../../components/store-dialog2/store-dialog2.component';

@Component({
  selector: 'app-exchange',
  templateUrl: './exchange.component.html',
  styleUrls: ['./exchange.component.scss']
})
export class ExchangeComponent implements OnInit {

  loading = false;
  showStores = !!this.apiService.balance;
  enough = false;
  overly = false;
  gifts = []
  generalDescription = 'Canjea tus certificados para abonar el saldo en tu cuenta.';
  description = 'Recuerda revisar los términos y condiciones de cada comercio.<br>Puedes elegir uno o más comercios.';
  certsConf = {
    effect: 'coverflow',
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    observer: true,
    watchOverflow: true,
    loop: false,
    coverflowEffect: {
      rotate: 0,  // con esto se inclina el certificado
      stretch: -15,
      depth: 100,
      modifier: 2,
      slideShadows: false,
    },
    navigation: {
      nextEl: null,
      prevEl: null
    },
    breakpoints: {
      768: {
        coverflowEffect: {
          stretch: 15
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }
      }
    }
  }
  confirmForm = new FormGroup({
    agree: new FormControl(false)
  })

  User;


  constructor(private apiService: ApiService, private router: Router, private dialog: MatDialog, private _snackBar: MatSnackBar) { }

  get invalidGifts(): boolean {
    return this.gifts.some(gift => !gift.value || gift.value <= 0);
  }

  async ngOnInit() {
    this.loading = true;
    this.User = await this.apiService.apiSynchronize('user');
    if (this.User.video == false) this.openVideo();
    let reload = true;
    let [rCerts, rStores, userCerts, userGifts] = await Promise.all([
      reload ? this.apiService.apiSynchronize('certsAvailable') : false,
      reload ? this.apiService.apiSynchronize('stores') : false,
      reload ? this.apiService.apiSynchronize('certs') : false,
      reload ? this.apiService.apiSynchronize('gifts') : false
    ]);

    this.loading = false;
    if (!rCerts) return;
    this.apiService.certs = rCerts;
    if (!rStores) return;

    this.apiService.stores = rStores.filter((store) => {
      return store.companyStores.map((sto) => sto.companyId).includes(this.apiService.user.companyId)
    }).map(store => ({
      ...store,
      show: false,
      values: [27, 31].includes(store.id) ? store.values : store.values.filter(value => value <= this.getSaldoRestante())
    }));

    const getStoreIndex = (id) => this.apiService.stores.findIndex(store => store.id == id);
    const lealtadCertificate = userCerts.find(certificate => certificate.categoryId == 354);
    const coinChanged = userGifts.some(gift => [27, 31].includes(gift.storeId))
    if (coinChanged) {
      this.apiService.stores.splice(getStoreIndex(27), 1);
      this.apiService.stores.splice(getStoreIndex(31), 1);
    }

    if (lealtadCertificate) {
      if (lealtadCertificate.status != 'inBalance') {
        this.apiService.stores.splice(getStoreIndex(27), 1);
        this.apiService.stores.splice(getStoreIndex(31), 1);
      }
    }
    let coinToHide;
    this.apiService.stores.forEach(store => {
      if (this.User.isTec || this.User.isSorteosTec || this.User.isTecSalud || this.User.isTecMilenio) {
        if ((this.User.isTec || this.User.isSorteosTec || this.User.isTecSalud) && store.showTec) coinToHide = getStoreIndex(31);
        if (this.User.isTecMilenio && store.showMilenio) coinToHide = getStoreIndex(27);
      } else if (this.User.isTecSec) {
        if (getStoreIndex(27) !== -1) this.apiService.stores.splice(getStoreIndex(27), 1);
        if (getStoreIndex(31) !== -1) this.apiService.stores.splice(getStoreIndex(31), 1);
      }
    })

    if (coinToHide) this.apiService.stores.splice(coinToHide, 1);
    if (this.apiService.gifts.length) {
      this.apiService.stores.forEach(store => {
        store.active = this.apiService.gifts.some(g => g.storeId == store.id)
      })
    }
    if (this.apiService.certs.length == 3) {
      this.apiService.certsSliderIndex = 1;
    }
    if (this.apiService.certs.length > 3 && (this.apiService.certs.length % 2 == 0)) {
      this.apiService.certsSliderIndex = this.apiService.certs.length / 2;
    }
    if (this.apiService.certs.length > 3 && (this.apiService.certs.length % 2 != 0)) {
      this.apiService.certsSliderIndex = Math.round(this.apiService.certs.length / 2) - 1;
    }

  }

  async changeCert() {
    let sliderIndex = this.apiService.certsSliderIndex;
    let cert = this.apiService.certs[sliderIndex];
    this.loading = true;
    let resp = await this.apiService.apiSynchronize('changeCert', { certificateId: cert.id });
    this.loading = false;
    let balance
    if (!resp) {
      this._snackBar.open('Ocurrió un error', 'Listo!', {
        duration: 5000
      });
      //balance = this.apiService.balance + cert.value;
    } else {
      if (resp.balance && resp.balance > 0) {
        balance = resp.balance;
        this.apiService.certs.splice(sliderIndex, 1);
        this.apiService.certsSliderIndex = 0;
        this.apiService.balance = balance;
        this.showStores = true;
      }
      if (!resp.balance) {
        this._snackBar.open('CERTIFICADO CANJEADO ANTERIORMENTE.', '¡ERROR!', {
          duration: 6000
        });
        this.apiService.certs.splice(sliderIndex, 1);
        this.apiService.certsSliderIndex = 0;
      }
    }
  }

  openDialogStore(store) {
    let canChange = true;
    if ([27, 31].includes(store.id)) {
      if (this.User.balance < store.values[0]) {
        console.log('saldo insuficiente');
        canChange = false;
      }
    }
    const dialog = this.dialog.open(StoreDialogComponent, {
      ...StoreDialogComponent.box,
      data: {
        store,
        useToSelect: true,
        canChange
      },
      autoFocus: false
    });
    dialog.afterClosed().subscribe(data => {
      if (!data) return;
      console.log(data)
      this.gifts.push(data.gift)
    })
  }

  openVideo() {
    const videoDialog = this.dialog.open(DialogVideoComponent, {
      ...DialogVideoComponent.box,
      panelClass: 'custom-dialog-container',
      data: this.User
    });
  }

  // openDialogStore2(store){
  //   this.dialog.open(StoreDialog2Component, {
  //     data: { store, useToSelect: true }
  //   });
  // }

  getPercentage() {
    let total = this.apiService.balance;
    let rest = this.getTotal(this.gifts);
    let percentage = Math.round(((total - rest) / total) * 100);
    this.enough = percentage == 0;
    this.overly = percentage < 0;
    return percentage;
  }

  getSaldoRestante() {
    let total2 = this.apiService.balance;
    let rest2 = this.getTotal(this.gifts);
    let saldorestante = Math.round(((total2 - rest2)));
    this.enough = saldorestante == 0;
    this.overly = saldorestante < 0;
    return saldorestante;
  }

  activeStores() {
    return this.apiService.stores.filter(s => s.active);
  }

  getStoreTotal(storeId) {
    return this.getTotal(this.getGiftsByStore(storeId));
  }

  getGiftsByStore(storeId) {
    return this.apiService.gifts
      .filter(g => g.storeId === storeId);
  }

  getTotal(arr) {
    return arr.reduce((t, g) => t + parseInt(g.value || 0), 0);
  }

  submitConfirm(e) {
    this.apiService.gifts = this.gifts.map((g, index) => ({ ...g, id: index + 1 }));
    if (this.apiService.certs.length) {
      return this.dialog.open(ExchangeCertDialogComponent);
    }
    this.router.navigate(['/canjear-certificados/confirmacion']);
  }

  removeGift(gift, index) {
    let store = this.apiService.stores.find(s => s.id === gift.storeId);
    store.active = false;
    this.gifts.splice(index, 1);
    this.apiService.removeGift(gift.id);
  }

  removeGiftByStore(store) {
    store.active = false;
    let gift = this.apiService.gifts.find(g => g.storeId === store.id);
    this.apiService.removeGift(gift.id);
  }

  get certs() {
    return this.apiService.certs || [];
  }
}
