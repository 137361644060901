import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ReportDialogComponent } from 'src/app/components/report-dialog/report-dialog.component';
import { MatDialog } from '@angular/material';
import { ApiService } from 'src/app/services/api.service';
import * as moment from 'moment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  loading = false;
  description = 'Revisa tus datos, si hay algún error da clic en "Reportar un problema".';
  profileForm = new FormGroup({
    oldPassword: new FormControl(null, [Validators.required]),
    newPassword: new FormControl(null, [Validators.required]),
    passValid: new FormControl(false, [Validators.requiredTrue]),
    country: new FormControl(this.apiService.user.country, [Validators.requiredTrue])
  });

  UserCampus = 'Sin Datos';

  CompanyName = 'Sin Datos';

  constructor(
    private dialog: MatDialog,
    private apiService: ApiService
  ) { }

  async ngOnInit() {
    let user = await this.apiService.apiSynchronize('user');
    this.CompanyName = this.apiService._company.name as string;
    if (user.isTec) this.CompanyName = 'Tecnológico de Monterrey';
    if (user.isSorteosTec) this.CompanyName = 'Sorteos TEC';
    if (user.isTecMilenio) this.CompanyName = 'Tecmilenio';
    if (user.isTecSalud) this.CompanyName = 'Tec Salud';
    if (user.isTecSec) this.CompanyName = 'Secundarias';
    let campus = await this.apiService.apiSynchronize('getCampus', { id: this.apiService.user.campusId });
    if (!campus) return;
    this.UserCampus = campus.name;
  }

  async send() {
    let { oldPassword, newPassword } = this.profileForm.value;
    this.loading = true;
    await this.apiService.apiSynchronize('passwordUpdate', { oldPassword, newPassword });
    this.loading = false;
    this.profileForm.reset();
    (<any>document.getElementById('passwordconf')).value = null;
  }

  showReportDialog(e) {
    e.preventDefault();
    this.dialog.open(ReportDialogComponent, ReportDialogComponent.box);
  }

  passIsEqual(e) {
    this.profileForm.get('passValid')
      .setValue(e.target.value === this.profileForm.get('newPassword').value);
  }

  obj2Date(d) {
    return moment(d).format('DD [de] MMMM');
  }

}
