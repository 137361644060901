import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators
 } from '@angular/forms';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  loading = false;
  description = "Revisa que la selección sea correcta.<br>De ser así, procede a terminar el canje.";
  certs = [];
  rows = [];
  form: FormGroup;
  ticketInfoForm: FormGroup;
  showInfo = false;
  showTicket = false;
  colonias = [];
  state;
  tickets = [];
  gifts = [];
  totalGifts = 0;

  constructor(private apiService: ApiService, private router: Router, private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      street: ['', Validators.required],
      number: ['', Validators.required],
      settlement: ['', Validators.required],
      zipCode: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      schedule: ['', Validators.required],
      phone: ['', Validators.required]
    });

    this.ticketInfoForm = this.formBuilder.group({
      name: ['', Validators.required],
      fLastName: ['', Validators.required],
      mLastName: ['', Validators.required],
      email: ['',[Validators.required,Validators.email]],
      country: ['', Validators.required],
      city: ['', Validators.required],
      phone: ['', Validators.required]
    });
   }

  async ngOnInit() {
    this.loading = true;
    this.certs = await this.apiService.apiSynchronize('certsInBalance');
    this.gifts = this.apiService.gifts;
    this.totalGifts = this.gifts.reduce((sum, gift) => (sum+parseInt(gift.value)), 0)
    //console.log(this.certs)
    this.certs = this.certs.filter(cert => cert.userId == this.apiService.user.id);
    this.loading = false;
    // se asigna certificado a cada regalo según los certificados con status inBalance,
    // se asignan de forma secuencial
    let certIndex = 0;
    //console.log(this.certs);
    //console.log(this.apiService.gifts);
    
    const tempCerts = this.certs.map((c:any) => ({...c, balance: +c.value })).sort((a,b) => a.id-b.id);
    const tempGifts = this.apiService.gifts.map((g:any) => ({...g, value: +g.value, balance: +g.value }));
    tempGifts.forEach(gift => {
      var giftValue = gift.value; // 1500
      let filteredCerts = tempCerts.filter(c => c.balance>0);
      filteredCerts.forEach(cert => {
        if(gift.balance<=0) return;
        var certBalance = cert.balance; //1000
        var value = gift.balance != giftValue ? gift.balance : giftValue;
        
        if(gift.balance <= certBalance) {
          cert.balance = certBalance - gift.balance;
          gift.balance = 0;
        } else if(gift.balance > certBalance) {
          gift.balance = gift.balance - certBalance;
          cert.balance = 0;
        }
        this.rows = this.rows || [];
        this.rows.push({
          cert,
          gift,
          value
        })
        
      })
    })
    console.log('this.rows', this.rows);
    

    this.apiService.gifts.forEach((g: any) => {
      if(!this.showInfo) this.showInfo = g.shippingInfo;
      if(!this.showTicket) this.showTicket = g.ticketInfo;
      else this.tickets.push(g);
      
      /*let c = this.certs[certIndex];
      c.balance = typeof c.balance === 'undefined' ? +c.value: c.balance;
      g.value = +g.value || 0;
      let balance = c.balance - g.value;
      this.rows = this.rows || [];
      this.rows.push({
        cert: c,
        gift: g,
        // usando la diferencia para registrar como valor de saldo
        // g.value + balance == g.value - (-balance)
        // observar balance >= 0 por tanto balance es negativo
        value: balance >= 0 ? g.value : g.value + balance
      });
      // si el regalo "cabe" dentro del certificado, solo se resta el valor
      // variable balance ya tiene esa diferencia
      if (balance > 0) {
        c.balance = balance;
      } else if (balance === 0) {
        // si el regalo termina con el cetificado de forma exacta, se pasa al siguiente certificado
        c.balance = balance;
        certIndex++;
      } else {
        // si el regalo ya no "cabe" en el certificado se toma la diferencia (la parte que cabe)
        // y se agrega el resto del regalo al siguiente certificado
        c.balance = 0;
        certIndex++;
        this.rows.push({
          cert: this.certs[certIndex],
          gift: g,
          value: -balance
        });
      }*/
    }); 
  }

  get totalUserBalance() {
    return this.apiService.user.balance;
  }

  get totalBalanceCerts() {
    return this.certs.reduce((sum, cert) => sum+parseInt(cert.value), 0);
  }

  calcCertTotal(certRow) {
    return certRow.cert.value - certRow.gifts.reduce((sum, gift) => sum + parseFloat(gift.value), 0);
  }

  async sendGiftsRequest() {
    this.loading = true;
    // para mandar al servidor, es necesario agrupar los certificados
    let newGifts = this.apiService.gifts.filter(gift => +gift.value>0).map((g: any) => ({
      value: parseInt(g.value),
      userId: this.apiService.user.id,
      storeId: g.storeId,
      certs: this.rows.filter(r => r.gift.id === g.id).map(r => r.cert.id),
      shippingInfo: g.shippingInfo ? this.form.value : null,
      ticketInfo: g.ticketInfo ? this.ticketInfoForm.value : null
    }));
    
    let payload = await this.apiService.apiSynchronize('sendNewGifts', newGifts);
    this.loading = false;
    if (!payload) return;
    this.router.navigate(['/inicio']);
    //setTimeout(this.Recargar,250)
  }

  get groupGiftsByCert() {
    //console.log(this.apiService.gifts);
    // console.log(this.certs);
    
    
    if (!this.apiService.gifts.length) return [];
    // para mostrar los regalos, es necesario a gruparlos por certificado
    return this.certs.map(c => {
      let gifts = this.rows.filter(r => {
        return c.id === r.cert.id;
      }).map(r => ({ ...r.gift, value: r.value }));
      return {
        cert: c,
        gifts
      };
    }).filter(c => c.gifts.length>0);
  }

  Recargar(){
    location.reload()
  }

  async zipCodeFinder(event){
    if(event.length>=5){
      this.colonias = []
      let CP = {colonia:'', estado:'',cp: event}
      this.apiService._codigopostal = CP;
      let dataCP = await this.apiService.apiSynchronize('getCP');
      console.log(dataCP);
      dataCP.forEach((data:any,index) => {
        if(index == 0){
          this.state = data.estado;
        }
        this.colonias.push(data.colonia);
      });
    }
  }

}
