import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import * as moment from 'moment';
import { UserApi } from 'src/app/services/shared/sdk';

@Component({
  selector: 'app-diploma',
  templateUrl: './diploma.component.html',
  styleUrls: ['./diploma.component.scss']
})
export class DiplomaComponent implements OnInit {

  User: any = {
    company: {}
  };
  Fecha: any = {}

  constructor(
    private apiService: ApiService,
    private userApi: UserApi) { }

  async ngOnInit() {
    this.User = await this.apiService.apiSynchronize('user');
    if (this.User.assistances && this.User.assistances.length > 0) {
      this.Fecha.dia = moment.utc(this.User.assistances[0].event.date).format('D');
      let meS = moment.utc(this.User.assistances[0].event.date).format('MMMM').toString();
      this.Fecha.mes = meS.charAt(0).toUpperCase() + meS.slice(1);
      this.Fecha.anio = moment.utc(this.User.assistances[0].event.date).year();
    }
  }

  Descargar() {
    document.getElementById('download').style.display = "none";
    document.getElementById('volver').style.display = "none";
    document.getElementById('head').style.display = "none";
    document.getElementById('footer').style.display = "none";
    alert('La siguiente ventana de sistema cuenta con ajustes por defecto para guardar o imprimir tu diploma. Realiza la siguiente configuración en la sección "Opciones/Más ajustes" para una visualización óptima: La opción de "encabezado y pie de página" desactivada, "Márgenes" en la opción de "Ninguno" y "Gráficos en segundo plano" deshabilitados.');

    if (!this.User.downloadedDiplomaDate) {
      this.apiService.apiSynchronize('userUpdate', { downloadedDiplomaDate: new Date() }).finally(() => {
        window.print();
      })
    } else {
      window.print();
    }
    document.getElementById('download').style.display = "block";
    document.getElementById('volver').style.display = "block";
    document.getElementById('head').style.display = "block";
    document.getElementById('footer').style.display = "block";
  }

}
