import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-cert-categories-dialog',
  templateUrl: './cert-categories-dialog.component.html',
  styleUrls: ['./cert-categories-dialog.component.scss']
})
export class CertCategoriesDialogComponent implements OnInit {
  static box = {
    width: '90%',
    height: '90vh',
    maxWidth: '900px',
    maxHeight: '545px',
    panelClass: 'no-gutters-dialog'
  };
  catShowing = null;
  images = [];

  constructor(
    private apiService: ApiService,
    private dialogRef: MatDialogRef<CertCategoriesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private selected = { cat: null, img: null }) {
  }

  ngOnInit() {
    this.showAll();
  }

  showAll(e?: Event) {
    if (e) e.preventDefault();
    this.catShowing = null;
    this.images = this.apiService.certCategories
      .reduce((images, category) => {
        images.push(...category.images
          .map(i => ({ cat: category.id, img: i })));
        return images;
      }, []);
  }

  showCat(cat, e?: Event) {
    if (e) e.preventDefault();
    this.catShowing = cat.id;
    this.images = cat.images.map(i => ({ cat: cat.id, img: i }));
  }

  isSelected(elem) {
    return elem.cat === this.selected.cat && elem.img === this.selected.img;
  }

  close() {
    this.dialogRef.close(this.selected);
  }

}
