import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog } from '@angular/material';
import {
  ReportDialogComponent,
  StoreDialogComponent
} from 'src/app/components';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { CertTemplateComponent } from '../../../components/cert-template/cert-template.component'

@Component({
  selector: 'app-gifts-archive',
  templateUrl: './gifts-archive.component.html',
  styleUrls: ['./gifts-archive.component.scss']
})
export class GiftsArchiveComponent implements OnInit {

  @Input() id: string;
  @Input() maxSize: number;
  @Output() pageChange: EventEmitter<number>;
  @Output() pageBoundsCorrection: EventEmitter<number>;
  p: number = 1;

  loading = false;
  description = 'Cuando tu regalo esté disponible el icono cambiará a rojo y podrás descargarlo. Recuerda que los regalos están disponibles dentro de 5 a 7 días hábiles después de la selección.';

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog,
    @Inject(DOCUMENT)
    private document: any,
    private router: Router) { }

  async ngOnInit() {
    if (this.apiService.giftsArchive.length) return;
    //console.log('this.apiService.giftsArchive', this.apiService.giftsArchive);

    this.loading = false;
    let gifts = await this.apiService.apiSynchronize('gifts');
    //console.log('gifts', gifts);

    this.loading = false;
    if (!gifts) this.apiService.giftsArchive = [];
    else this.apiService.giftsArchive = gifts.reverse();
    this.loading = false;
    //console.log('this.apiService.giftsArchive', this.apiService.giftsArchive);

  }

  giftStatus(status) {
    switch (status) {
      case 'process':
      case null:
        return 'En proceso';
      case 'available':
        return 'Disponible';
      case 'inBalance':
        return 'Canjeado';
      case 'expired':
        return 'Expirado';
      default:
        return status;
    }
  }

  showReportDialog(gift) {
    this.dialog.open(ReportDialogComponent, {
      ...ReportDialogComponent.box,
      data: gift
    });
  }

  showGiftDialog(gift) {
    this.dialog.open(StoreDialogComponent, {
      ...StoreDialogComponent.box,
      data: gift
    });
  }

  showTemplate(gift) {
    this.dialog.open(CertTemplateComponent, {
      width: '80%',
      data: gift
    });
  }

  categories(categories) {
    if (!categories) return '';
    return categories.map(c => c.name).join(', ');
  }

  async downloadFile(gift) {
    // return console.log(gift);

    if (!gift.updatedAt) {
      let giftEdit = await this.apiService.apiSynchronize('editGift', { id: gift.id, updatedAt: new Date() });
    }
    /// Estas funciones son las que deben estar
    if (gift.file) {
      window.open("http://apin.giftpedia.com.mx/api/containers/cloudgiftmaster/download/" + gift.file, '_blank')
    }
    if (gift.store.isLink) {
      window.open(gift.link, '_blank');
    }
    if (gift.store.isGenerated) {
      this.router.navigate(['/template', gift.id]);
    }
  }

}
