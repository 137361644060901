import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-resetpass',
  templateUrl: './resetpass.component.html',
  styleUrls: ['./resetpass.component.scss']
})
export class ResetpassComponent implements OnInit {

  loading = false;
  wellcome = 'Ingresa la nueva contraseña.La longitud m&iacute;nima de contraseña: 9 caracteres. No puede contener caracteres repetidos secuencialmente.<br><br>';
  sended = false;

  pass1;
  pass2;

  resetpassForm = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.minLength(9)]),
    password2: new FormControl('', [Validators.required, Validators.minLength(9)])
  });

  constructor(private router: Router, private apiService: ApiService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(async (params) => {

      if (params) {
        let auxToken = {
          token: params['token'],
          userId: params['id']
        }

        let token = await this.apiService.apiSynchronize('verifyToken', auxToken);
        if (token) {
          sessionStorage.setItem('token', params['token']);
          sessionStorage.setItem('userId', params['id']);
          this.apiService.user = token;
        } else {
          this.router.navigate(['acceso'])
        }
      } else {
        this.router.navigate(['acceso']);
      }
    })
  }

  async resetPass() {

    this.loading = true;
    let resp = await this.apiService.apiSynchronize('resetPass2', { newPassword: this.resetpassForm.value.password });
    this.loading = false;
    if (!resp) return;
    this.sended = true;
  }

  async volver() {
    this.loading = true;
    await this.apiService.logout();
    this.loading = false;
  }

}
