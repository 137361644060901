import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})

export class NotAuthService implements CanActivate {

  constructor(public router: Router, private apiService: ApiService) {

  }

  async canActivate() {
    if (this.apiService.token) {
      this.router.navigate(['/inicio']);
    }
    return !this.apiService.token;
  }
}
