/* tslint:disable */
import {
  Company,
  User
} from '../index';

declare var Object: any;
export interface BalanceHistoryInterface {
  "createdAt"?: Date;
  "companyId"?: number;
  "balance"?: number;
  "balanceUsed"?: number;
  "adminId"?: number;
  "status"?: string;
  "updatedAt"?: Date;
  "expirationDate"?: Date;
  "id"?: number;
  company?: Company;
  admin?: User;
}

export class BalanceHistory implements BalanceHistoryInterface {
  "createdAt": Date;
  "companyId": number;
  "balance": number;
  "balanceUsed": number;
  "adminId": number;
  "status": string;
  "updatedAt": Date;
  "expirationDate": Date;
  "id": number;
  company: Company;
  admin: User;
  constructor(data?: BalanceHistoryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `BalanceHistory`.
   */
  public static getModelName() {
    return "BalanceHistory";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of BalanceHistory for dynamic purposes.
  **/
  public static factory(data: BalanceHistoryInterface): BalanceHistory{
    return new BalanceHistory(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'BalanceHistory',
      plural: 'BalanceHistories',
      path: 'BalanceHistories',
      idName: 'id',
      properties: {
        "createdAt": {
          name: 'createdAt',
          type: 'Date',
          default: new Date(0)
        },
        "companyId": {
          name: 'companyId',
          type: 'number'
        },
        "balance": {
          name: 'balance',
          type: 'number'
        },
        "balanceUsed": {
          name: 'balanceUsed',
          type: 'number'
        },
        "adminId": {
          name: 'adminId',
          type: 'number'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date',
          default: new Date(0)
        },
        "expirationDate": {
          name: 'expirationDate',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        company: {
          name: 'company',
          type: 'Company',
          model: 'Company',
          relationType: 'belongsTo',
                  keyFrom: 'companyId',
          keyTo: 'id'
        },
        admin: {
          name: 'admin',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'adminId',
          keyTo: 'id'
        },
      }
    }
  }
}
