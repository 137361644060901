import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-report-form',
  templateUrl: './report-form.component.html',
  styleUrls: ['./report-form.component.scss']
})
export class ReportFormComponent implements OnInit {
  loading = false;
  reportForm = new FormGroup({
    category: new FormControl('', [Validators.required]),
    description: new FormControl(null, [Validators.required])
  });
  @Input() labels: boolean = true;
  @Input() data;
  @Output() sended = new EventEmitter<boolean>();

  constructor(private apiService: ApiService, private _snackBar: MatSnackBar) { }
   company;
  async ngOnInit() {
    this.company = await this.apiService.apiSynchronize('company');
  }

  async send() {
    this.loading = true;
    let payload = await this.apiService.report({
      ...this.reportForm.value,
      extraData: this.data,
      companyId: this.company.id
    });
    if(payload) {
      this._snackBar.open('Regresaremos contigo para brindarte una solución a este error', '¡Enviado!', {
        duration: 5000
      });
    }
    this.loading = false;
    if (!payload) {
      this._snackBar.open('Error al enviar el reporte', 'ERROR', {
        duration: 5000
      });
    }
    this.sended.emit(true);
  }

}
