import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthService } from './services/auth.service';
import { RegisteredService } from './services/registered.service';
import { ResolveRegisterTokenService } from './services/resolve-register-token.service';
import {
  AccessComponent,
  BalanceComponent,
  GiftsArchiveComponent,
  CertsArchiveComponent,
  CheckoutComponent,
  ExchangeComponent,
  HelpComponent,
  HomeComponent,
  LoginComponent,
  PrivacyComponent,
  ProfileComponent,
  RegisterComponent,
  ResetPasswordComponent,
  SessionComponent,
  TermsComponent,
  ConfirmComponent,
  RegisterSSOComponent
} from "./views";
import { CertTemplateComponent } from './components/cert-template/cert-template.component'
import { ResetpassComponent } from './views/session/resetpass/resetpass.component';
import { TerminosComponent } from './views/access/terminos/terminos.component';
import { DiplomaComponent } from './components/diploma/diploma.component';
import { NotAuthService } from './services/not-auth.service';
import { EventInvitationService } from './services/event-invitation.service';

const routes: Routes = [

  {
    path: '', component: SessionComponent,
    canActivate: [AuthService],
    children: [
      {
        path: '',
        children: [
          {
            path: '', component: HomeComponent,
            children: [
              { path: '', redirectTo: 'inicio', pathMatch: 'full' },
              {
                path: 'inicio',
                component: GiftsArchiveComponent
              },
              {
                path: 'certificados',
                component: CertsArchiveComponent
              },
              {
                path: 'saldo',component: BalanceComponent
              },
              //{
              //  path: 'aviso-privacidad', component: PrivacyComponent
              //},
            ]
          },
          { path: 'canjear-certificados/confirmacion', component: CheckoutComponent },
          { path: 'canjear-certificados', component: ExchangeComponent },
          { path: 'perfil', component: ProfileComponent },
        ],
        canActivate: [RegisteredService, EventInvitationService]
      },
      {
        path: 'ayuda', component: HelpComponent
      },
      // {
      //   path: 'terminos', component: TermsComponent
      // },
      {
        path: 'terminos/comercio/:id', component: TermsComponent
      },
  
      {
        path: 'confirmacion', component: ConfirmComponent
      },
      {
        path: 'diploma', component: DiplomaComponent,
      }
    ]
  },
  {
    path: 'template/:id', component: CertTemplateComponent,
    canActivate: [AuthService],
  },
  // {
  //   path: 'diploma', component: DiplomaComponent,
  //   canActivate: [AuthService],
  // },
  {
    path: '', component: AccessComponent,
    canActivate: [NotAuthService],
    children: [
      { path: 'acceso', component: LoginComponent },
      {
        path: 'aviso-privacidad', component: PrivacyComponent
      },
      { path: 'restablecer', component: ResetPasswordComponent },
      {
        path: 'restablecer/:id/:token', component: ResetpassComponent
      }
    ]
  },
  {
    path: 'privacy', component: TerminosComponent
  },
  {
    path: 'registro/:accessToken/:id',
    component: RegisterSSOComponent
  },
  {
    path: 'registro',
    pathMatch: 'full',
    component: RegisterComponent,
    canActivate: [AuthService, RegisteredService]
  },
  { path: '**', pathMatch: 'full', redirectTo: 'acceso' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
