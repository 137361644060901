import { Component } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApiService } from './services/api.service';
import { LoopBackAuth, LoopBackConfig, SDKToken } from './services/shared/sdk';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'giftpedia';
  scrollTimeout;
  constructor(
    router: Router,
    private auth: LoopBackAuth,
    private apiService: ApiService
  ) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.onActivateRoute(e);
      }
    });
    const apiUrl = environment.apiUrl.split('/api/')
    LoopBackConfig.setBaseURL(apiUrl[0]);
  }
  onActivateRoute(e) {
    if (e.url.match(/\/canjear-certificados\/\d+/)) {
      return;
    }
    clearTimeout(this.scrollTimeout);
    this.scrollTimeout = setTimeout(() => {
      let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
          window.scrollTo(0, pos - 20);
        } else {
          window.clearInterval(scrollToTop);
        }
      }, 16);
    }, 400);
  }
}
