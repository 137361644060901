import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss']
})
export class BalanceComponent implements OnInit {

  p: number = 1;

  loading: boolean = false;
  description = 'Recuerda que puedes acumular el saldo de tus certificados.<br>Revisa la vigencia para que puedas hacer el canje (abono a tu cuenta de saldo) antes de la fecha indicada.<br>Para canjear tus regalos es necesario ir a la pestaña “Canjear”.';

  constructor(private apiService: ApiService) { }

  async ngOnInit() {
    this.loading = true;
    let certs = await this.apiService.apiSynchronize('certs');
    //let gifts = await this.apiService.apiSynchronize('gifts');
    this.loading = false;
    //if(!gifts) return;
    //this.apiService.giftsArchive = gifts;
    if (!certs) return;
    this.apiService.certs = certs;
    if(this.apiService.certs.length){
      this.apiService.certs.forEach((cert: any) => {
        cert.gifts = [];
        this.apiService.giftsArchive.forEach((gift: any) => {
          if(gift.certificateDetail){
            gift.certificateDetail.forEach((certD: any) => {
              if(certD.certId == cert.id){
                cert.gifts.push({
                  id: gift.store.name,
                  amount: certD.amount,
                  date: certD.date ? certD.date : new Date()
                })
                 cert.total = cert.total + certD.amount;
              }
            })
          }
        })
        
      })
    }
  }

}
