import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';


@Component({
  selector: 'app-cert-resume-dialog',
  templateUrl: './cert-resume-dialog.component.html',
  styleUrls: ['./cert-resume-dialog.component.scss']
})
export class CertResumeDialogComponent implements OnInit {
  static box = {
    width: '90%',
    height: '90vh',
    maxWidth: '980px',
    maxHeight: '350px'
  };
  loading = false;
  schedule = [];
  constructor(private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) private data,
    private dialogRef: MatDialogRef<CertResumeDialogComponent>,
    private router: Router
  ) {
    console.log(data);
    this.schedule = data.schedule.map((s) => {
      let icon = 'person';
      let textId = 'Colaborador: ' + s.name;
      if (s.dest === 'group') {
        icon = 'people';
        textId = `Grupo: ${s.name} (${s.length} personas)`;
      }

      return {
        icon, textId,
        date: s.date, length: s.length,
        value: s.value
      };
    });
  }

  ngOnInit() {
  }

  get total() {
    const reduce = (t, line) => t + (line.value * line.length);
    return this.schedule.reduce(reduce, 0);
  }

  async close() {
    let data;
    if (this.data.patch) {
      let d = this.data.schedule[0];
      data = {
        userId: d.dest === 'user' ? d.destId : undefined,
        groupId: d.dest === 'group' ? d.destId : undefined,
        img: d.img.split('/').pop(),
        date: d.date,
        value: d.value,
        certificateCategoryId: d.certCategoryId,
        description: d.description
      }
    } else {
      data = this.data.schedule.map(s => ({
        userId: s.dest === 'user' ? s.destId : undefined,
        groupId: s.dest === 'group' ? s.destId : undefined,
        img: s.img.split('/').pop(),
        date: s.date,
        value: s.value,
        certificateCategoryId: s.certCategoryId,
        description: s.description
      }));
    }
    this.loading = true;
    let response = await this.apiService
      .apiSynchronize(this.data.patch ? 'editSchedule' : 'sendSchedule', data);
    this.loading = false;
    if (!response) return false;
    this.dialogRef.close();
    this.router.navigate(['/envios-programados']);
  }

}
