import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import * as moment from 'moment';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  loading = false;
  assistance = {
    status: null,
    guest: 0,
    event: {
      date: new Date(),
      startTime: '',
      link: '',
      campus: {
        name: ''
      }
    }
  };
  show = false;


event_date = null;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private dialog: MatDialog
  ) { }

  async ngOnInit() {
    // if (this.apiService.assistances.length) return;
    // this.loading = true;
    let assistances = await this.apiService.apiSynchronize('assistances');
    this.loading = false;
    if (!assistances || !assistances.length) return this.router.navigate(['/canjear-certificados']);
    this.apiService.assistances = assistances;
    this.assistance = assistances[0];
    //console.log(this.assistance);
    this.event_date =  moment.utc(this.assistance.event.date).format('DD-MMM-YYYY');
    // console.log(this.event_date);
    const employee = this.apiService.user;
    let now = moment();
    let dateEvent = moment(this.event_date);
    let diff = dateEvent.diff(now, 'days');
    // console.log("diff", diff);
    // console.log(this.assistance)
    if (diff <= 2) {
      this.show = this.assistance.status=false;
      if (this.assistance.status == null) await this.confirmAttendance();
      return this.router.navigate(['/canjear-certificados']);
    } else {
      this.show = true;
    }
  }

  toggleHasConfirmed() {
    this.assistance.status = !this.assistance.status;
    if (!this.assistance.status) {
      this.assistance.guest = 0;
    } else {
      this.assistance.guest = 0;
    }
  }

  onChangeGuestNumber(add: boolean) {
    if (!this.assistance.status) {
      this.assistance.guest = 0;
      return false;
    }
    if (
      this.assistance.guest === 1 && !add ||
      this.assistance.guest === 5 && add
    ) {
      return false;
    }

    const adding: number = add ? 1 : -1;
    this.assistance.guest = this.assistance.guest + adding;
  }

  async confirmAttendance() {
    let response = await this.apiService
      .apiSynchronize('editAssistance', this.assistance);
    this.loading = false;
    if (!response) return false;
    this.openConfirmDialog();
    this.apiService.assistances = [response];
    setTimeout(() => {
      this.router.navigate(['/canjear-certificados']);
    }, 3000);
  }

  openConfirmDialog() {
    const modal = this.dialog.open(ConfirmDialogComponent, {
      width: '50%'
    })
    modal.afterClosed().subscribe(() => this.router.navigate(['/canjear-certificados']))
  }

}
