import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: '[cert]',
  templateUrl: './cert-card.component.html',
  styleUrls: ['./cert-card.component.scss']
})
export class CertCardComponent implements OnInit {
  @Input() cert = {};
  @Input() mb: boolean = true;

  constructor(private apiService: ApiService) { }

  ngOnInit() {
  }

}
