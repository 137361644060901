import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class EventInvitationService implements CanActivate {

  constructor(public router: Router, private apiService: ApiService) { }

  async canActivate(active: ActivatedRouteSnapshot, route: RouterStateSnapshot) {
    let assistances = this.apiService.assistances;
    if (assistances && assistances.length && assistances[0].status == null) return this.router.navigate(['/confirmacion']);
    return true;
  }

}