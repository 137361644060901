import { BreakpointsComponent } from "./breakpoints/breakpoints.component";
import { CalendarDialogComponent } from "./calendar-dialog/calendar-dialog.component";
import { CancelScheduledDialogComponent } from "./cancel-scheduled-dialog/cancel-scheduled-dialog.component";
import { CardColorComponent } from "./card-color/card-color.component";
import { CertCardComponent } from "./cert-card/cert-card.component";
import { CertCategoriesDialogComponent } from "./cert-categories-dialog/cert-categories-dialog.component";
import { CertDialogComponent } from "./cert-dialog/cert-dialog.component";
import { CertFormComponent } from "./cert-form/cert-form.component";
import { CertResumeDialogComponent } from "./cert-resume-dialog/cert-resume-dialog.component";
import { CompanyInfoComponent } from "./company-info/company-info.component";
import { ErrorDialogComponent } from "./error-dialog/error-dialog.component";
import { ExchangeCertDialogComponent } from "./exchange-cert-dialog/exchange-cert-dialog.component";
import { FaqsComponent } from "./faqs/faqs.component";
import { FooterComponent } from "./footer/footer.component";
import { HeaderAdminComponent } from "./header-admin/header-admin.component";
import { HeaderComponent } from "./header/header.component";
import { IconsComponent } from "./icons/icons.component";
import { InstructionsComponent } from "./instructions/instructions.component";
import { LoadingComponent } from "./loading/loading.component";
import { LoginFormComponent } from "./login-form/login-form.component";
import { PageAdminComponent } from "./page-admin/page-admin.component";
import { PageComponent } from "./page/page.component";
import { ReportDialogComponent } from "./report-dialog/report-dialog.component";
import { ReportFormComponent } from "./report-form/report-form.component";
import { ResetPassFormComponent } from "./reset-pass-form/reset-pass-form.component";
import { RouterTabsComponent } from "./router-tabs/router-tabs.component";
import { StoreDialogComponent } from "./store-dialog/store-dialog.component";

export {
  BreakpointsComponent,
  CalendarDialogComponent,
  CancelScheduledDialogComponent,
  CardColorComponent,
  CertCardComponent,
  CertCategoriesDialogComponent,
  CertDialogComponent,
  CertFormComponent,
  CertResumeDialogComponent,
  CompanyInfoComponent,
  ErrorDialogComponent,
  ExchangeCertDialogComponent,
  FaqsComponent,
  FooterComponent,
  HeaderAdminComponent,
  HeaderComponent,
  IconsComponent,
  InstructionsComponent,
  LoadingComponent,
  LoginFormComponent,
  PageAdminComponent,
  PageComponent,
  ReportDialogComponent,
  ReportFormComponent,
  ResetPassFormComponent,
  RouterTabsComponent,
  StoreDialogComponent,
};
