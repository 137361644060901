import { Injectable } from '@angular/core';
import { MatDialog, MatSnackBar } from "@angular/material";
import { Router } from '@angular/router';
import * as buildUrl from "build-url";
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';

const method = (m) => {
  return !environment.local ? m : 'GET';
}

interface DateSchedule {
  day: number;
  month: number;
}

interface User {
  id: number;
  name: string;
  email: string;
  birthdate: Date;
  maritalStatus: 'married' | 'single';
  anniversary: DateSchedule;
  birthdateCouple: DateSchedule;
  parentType: string | boolean;
  childrenBirthdates: DateSchedule[];
  dateAdmission: string;
  payrollNumber: string;
  emailVerified: boolean;
  registered: boolean;
  balance: number;
  country: string;
  companyId: number;
}

interface ShortUserOrGroup {
  id: number;
  name: string;
  users?: {
    id: number;
    name: string;
  }[]
}

interface Store {
  id: number;
  name: string;
  description: string;
  logo: string;
  img: string;
  terms: string;
  termsExcerpt: string;
  values: number[];
  active?: boolean;
  productType?: number;
  showMilenio?: boolean;
  showTec?: boolean;
  delivery?: number;
  isTicket?: boolean;
  isGenerated?: boolean;
  isFile?: boolean;
  isLink?: boolean;
}

interface Cert {
  id: number;
  img: string;
  title: string;
  description: string;
  value: number;
  total: number;
  expirationDate: string;
  categoryId: number;
  sliderActive?: boolean;
  collapseOpen?: boolean;
  status?: string;
  modify?: { status: string, date: Date }[],
  category?: CertCategory
}

interface Gift {
  id: number;
  value: number;
  date: string;
  userId: number;
  storeId: number;
  status?: 'process' | 'available' | 'expired' | 'changed';
  store?: Store;
  rows: { cert: Cert, value: number }[];
  file: string;
  shippingInfo: any;
  ticketInfo: any;
  code: string;
  link: string;
  nip: string;
  info: Array<any>;
  expirationDate: Date;
  certificateDetail: any[]
}

// se requiere para la categorías de certificados, necesita una fecha o un identificador
interface ImportantDate {
  title: string;
  date: DateSchedule | 'birthdate' | 'anniversary' | 'birthdateCouple' | 'dateAdmission' | 'childBirthdate' | Date;
  left?: number;
}

// Únicamente sirve para ahorrar tiempo de escribir a Empresa?
interface CertTemplate {
  id: number;
  name: string;
  description: string;
}
// Tiene una descripción donde se coloca "Tipos de diseño"
interface CertCategory {
  id: number;
  name: string;
  images: string[];
  description: string;
  dateDefault: DateSchedule | 'birthdate' | 'anniversary' | 'birthdateCouple' | 'dateAdmission' | 'childBirthdate' | Date;
  active: boolean;
  selected?: boolean;
  companyId?: number;
}
//programación de certificados
interface CertSchedule {
  id: number;
  userId?: number,
  user?: User,
  groupId?: number,
  group?: Group,
  img: string,
  createdAt?: Date;
  date: Date,
  value: number,
  certificateCategoryId: number,
  category?: CertCategory,
  description: string
}
interface Group {
  id: number;
  name: string;
  users: (User | number)[];
}
interface Company {
  id: number;
  name: String;
  balance: number;//saldo
  //value
  total: number;
  users?: ShortUserOrGroup[];
  groups?: ShortUserOrGroup[];
  certs?: Cert[];
  schedule?: CertSchedule[];
}
interface Faqs {
  id: number;
  question: string;
  answer: string;
  category: string;
}
interface Settings {
  name: string;
  logo: string;
  phone: string;
  office: string;
  workingHours: string;
  email: string;
  facebook: string;
  linkedin: string;
  instagram: string;
}
interface Assistance {
  id: number;
  userId: number;
  eventId: number;
  status: boolean;
  guest: number;
}

interface CodigoPostal {
  colonia: string;
  estado: string;
  cp: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  _userId: number;
  _user: User;
  _token: string | boolean = false;
  _certs: Cert[] = [];
  _certActive: Cert;
  _gift: Gift;
  _gifts: Gift[] = [];
  _giftsArchive: any[] = [];
  _dates: ImportantDate[] = [];
  _stores: Store[] = [];
  _certCategories: CertCategory[] = [];
  _certTemplates: CertTemplate[] = [];
  _users: ShortUserOrGroup[] = [];
  _groups: ShortUserOrGroup[] = [];
  _company: Company;
  _schedule: CertSchedule[] = [];
  _faqs: Faqs[] = [];
  _settings: Settings = <Settings>{};
  _certsArchive: Cert[] = [];
  _notifications: ImportantDate[] = [];
  _assistances: Assistance[] = [];
  _codigopostal: CodigoPostal;

  terms: string | boolean = false;
  privacy: string | boolean = false;

  constructor(private dialog: MatDialog, private router: Router, public snackBar: MatSnackBar) {

  }

  bucket(hash) {
    if (hash != "https://adminngift.s3-us-west-2.amazonaws.com/assets/images/welcome/bienvenido.png") {
      return environment.apiUrl + environment.bucket + hash;
    }
    else {
      return hash;
    }
  }

  set balance(value) {
    this._user.balance = value;
  }

  get balance() {
    return this.user.balance || 0;
  }

  set users(users) {
    this._users = users.map(u => ({
      id: u.id,
      name: u.name,
    }));
  }

  get users() {
    return this._users;
  }

  set groups(groups) {
    this._groups = groups.map(g => ({
      id: g.id,
      name: g.name,
      users: g.users
    }));
  }

  get groups() {
    return this._groups;
  }

  groupById(id) {
    return this._groups.find(g => g.id === parseInt(id));
  }

  certsProcess(certs) {
    return certs.map(c => ({
      id: c.id,
      img: this.bucket(c.img),
      title: c.title,
      description: c.message,
      value: c.value,
      value2: c.valuehistory,
      expirationDate: c.expirationDate,
      total: c.total || 0,
      category: c.category || {},
      categoryId: c.categoryId
    }));
  }

  set assistances(assistances) {
    this._assistances = assistances.map((a) => {
      return {
        id: a.id,
        userId: a.userId,
        eventId: a.eventId,
        status: a.status,
        guest: a.guest
      }
    });
  }

  get assistances() {
    return this._assistances;
  }

  set certs(certs) {
    this._certs = this.certsProcess(certs);
    this.certActive = this._certs[0];
  }

  get certs() {
    return this._certs;
  }

  get gift() {
    return this._gift;
  }

  set certsArchive(certs) {
    this._certsArchive = this.certsProcess(certs);
  }

  get certsArchive() {
    return this._certsArchive;
  }

  set certActive(cert) {
    // el cetificado activo no debe coincidir precisamente con la bandera active, ya que active se usa para el scroll
    this._certActive = cert;
  }

  get certActive() {
    return this._certActive;
  }

  set certCategories(certCategories) {
    this._certCategories = certCategories.map(cc => ({
      id: cc.id,
      name: cc.name,
      images: cc.images ? cc.images.map(img => this.bucket(img)) : [],
      description: cc.description,
      dateDefault: cc.dateDefault,
      active: false,
      selected: false,
      companyId: cc.companyId
    }));
  }

  get certCategories() {
    return this._certCategories;
  }

  set certTemplates(certTemplates) {
    this._certTemplates = certTemplates.map(ct => ({
      id: ct.id,
      name: ct.name,
      description: ct.description
    }));
  }

  get certTemplates() {
    return this._certTemplates;
  }

  set stores(stores: any[]) {
    this._stores = stores.map(s => ({
      id: s.id,
      name: s.name,
      description: s.description,
      logo: this.bucket(s.logo),
      img: this.bucket(s.giftDesign),
      terms: s.terms,
      termsExcerpt: s.termsExcerpt,
      values: s.values,
      active: false,
      productType: s.productType,
      showMilenio: s.showMilenio,
      showTec: s.showTec,
      delivery: s.delivery,
      isTicket: s.isTicket,
      isGenerated: s.isGenerated,
      isFile: s.isFile,
      isLink: s.isLink
    }));
  }

  get stores() {
    return this._stores;
  }

  getStoreById(storeId) {
    let store = this._stores.find(s => s.id === storeId);
    return store;
  }

  storeSetActive(storeId) {
    this.getStoreById(storeId).active = true;
  }

  removeGift(giftId) {
    let giftIndex = this.gifts.findIndex(g => g.id === giftId);
    this.gifts.splice(giftIndex, 1);
    this.gifts.forEach((g, i) => {
      g.id = (i + 1);
    })
  }

  setGift(gift) {
    gift.id = this._gifts.length + 1;
    this._gifts.push(gift);
  }

  findCert(certId) {
    return this._certs.find(c => c.id === parseInt(certId));
  }

  get certsCheckoutLength() {
    return 0;
  }

  set giftsArchive(gifts: any[]) {
    this._giftsArchive = gifts.map(g => ({
      id: g.id,
      value: g.value,
      date: g.createdAt,
      userId: g.userId,
      certId: g.certificateId,
      storeId: g.storeId,
      status: g.status,
      store: {
        ...g.store,
        img: this.bucket(g.store.giftDesign),
        logo: this.bucket(g.store.logo)
      },
      certs: g.certificates,
      categories: g.categories,
      file: g.file,
      shippingInfo: g.shippingInfo,
      ticketInfo: g.ticketInfo,
      code: g.code,
      link: g.link,
      nip: g.nip,
      info: g.info,
      expirationDate: g.expirationDate,
      certificateDetail: g.certificateDetail,
      updatedAt: g.updatedAt
    }));
  }

  get giftsArchive() {
    return this._giftsArchive;
  }

  get gifts() {
    return this._gifts;
  }

  set gifts(gifts) {
    this._gifts = gifts;
  }

  get isRegistered() {
    return environment.production || environment.demo ? this._user && this._user.registered : true;
  }

  get token() {
    if (!this._token) {
      let token = localStorage.getItem('token') || sessionStorage.getItem('token');
      this._userId = parseInt(localStorage.getItem('userId') || sessionStorage.getItem('userId'));
      this._token = token;
    }
    return this._token;
  }

  get Userid() {
    if (!this._userId) {
      let userId = parseInt(localStorage.getItem('userId') || sessionStorage.getItem('userId'));
      this._userId = userId;
    }
    return this._userId;
  }

  set certsSliderIndex(n: number) {
    this._certActive = this.certs[n];
  }

  get certsSliderIndex(): number {
    if (!this._certActive) return null;
    return this.certs.findIndex(c => c.id === this._certActive.id);
  }

  get dates() {
    return this._dates;
  }

  set company(c) {
    if (!c) return;
    this._company = {
      id: c.id,
      name: c.name,
      balance: c.balance,
      total: c.total
    };
    this._users = c.users;
    this._groups = c.groups;
    this._certs = c.certs;
    this._schedule = c.schedule;
  }

  get company() {
    return this._company;
  }

  set codigoPostal(c) {
    this._codigopostal = c;
  }

  get codigoPostal() {
    return this._codigopostal;
  }

  report(form) {
    if (!this._userId) { this._userId = this._user.id }
    return this.apiSynchronize('report', {
      senderId: this._userId,
      type: form.category,
      status: 'activo',
      description: form.description,
      extraData: form.data,
      companyId: form.companyId
    });
  }

  openErrorDialog(e) {
    this.dialog.open(ErrorDialogComponent, {
      ...ErrorDialogComponent.box,
      data: e
    });
  }

  apiSynchronize(type, data?: any) {

    const PATCH = method('PATCH');
    const POST = method('POST');
    const DELETE = method('DELETE');
    const GET = method('GET');

    const QUERY_TOKEN = {
      access_token: <string>this.token
    };
    const HEADERS = {
      accepts: 'application/json',
      'content-type': 'application/json'
    };
    const BODY = data ? JSON.stringify(data) : undefined;


    const buildApiUrl = (params) => {
      return buildUrl(environment.apiUrl, Object.assign({
        path: '',
        queryParams: QUERY_TOKEN
      }, params));
    }

    const buildApiUrlAux = (params) => {
      return buildUrl(environment.apiUrl, Object.assign({
        path: ''
      }, params));
    }

    const tryResponse = async (r) => {
      try {
        var payload = await r.json();
      } catch (error) {
        // si el error es SyntaxError quiere decir que el servidor a mandado una respuesta vacía
        if (error.name != 'SyntaxError')
          console.error(type + ':', error);
        payload = r.ok ?
          { done: true } :
          { error: { name: "Error", message: "Error parsing json response" } }
      }
      return payload;
    };


    let promise = Promise.resolve(new Response());
    switch (type) {
      case 'login': {
        let url = buildApiUrl({
          path: '/users/login',
          queryParams: {
            include: 'user'
          }
        });

        promise = promise.then(() => fetch(url, {
          method: POST,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }
      case 'logout': {
        let url = buildApiUrl({ path: '/users/logout' });
        promise = promise.then(() => fetch(url, {
          method: POST,
          headers: HEADERS
        }));
        break;
      }
      case 'user': {
        if (!this._userId) { this._userId = this._user.id }
        let url = buildApiUrl({
          path: ('/users/' + this._userId) + (environment.local ? '/index' : ''),
          queryParams: {
            ...QUERY_TOKEN,
            filter: JSON.stringify({
              include: [{
                relation: 'company',
                scope: {
                  include: [{
                    relation: 'questions',
                    scope: {
                      where: {
                        isDeleted: false
                      }
                    }
                  }]
                }
              },
              {
                relation: 'assistances',
                scope: {
                  include: 'event'
                }
              }]
            })
          }
        });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'users': {
        let url = buildApiUrl({
          path: '/users' + (environment.local ? '/index' : ''),
          queryParams: {
            ...QUERY_TOKEN,
            filter: JSON.stringify({
              where: { companyId: this._company.id },
              fields: ['id', 'name']
            })
          }
        });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'groups': {
        let url = buildApiUrl({
          path: '/Groups',
          queryParams: {
            ...QUERY_TOKEN,
            filter: JSON.stringify({
              where: { companyId: this._company.id },
              fields: ['id', 'name'],
              include: [{
                relation: 'users', scope: {
                  fields: ['id', 'name']
                }
              }]
            })
          }
        });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'settings': {
        let url = buildApiUrl({ path: '/Settings' });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'company': {
        let url = buildApiUrl({
          path: ('/Companies/' + this.user.companyId) + (environment.local ? '/index' : '')
        });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'register': {
        let url = buildApiUrl({ path: '/users/reset-password' });
        promise = promise.then(() => fetch(url, {
          method: POST,
          headers: HEADERS,
          body: (!environment.local ?
            JSON.stringify({ newPassword: data.newPassword }) : undefined)
        }));
      }
      case 'userUpdate': {
        let url = buildApiUrl({ path: ('/users/' + this._user.id) + (environment.local ? '/index' : '') });
        data.password = undefined;
        delete data.password;
        promise = promise.then(() => fetch(url, {
          method: PATCH,
          headers: HEADERS,
          body: (!environment.local ?
            JSON.stringify(data) : undefined)
        }));
        break;
      }
      case 'passwordUpdate': {
        let url = buildApiUrl({ path: '/users/change-password' });
        promise = promise.then(() => fetch(url, {
          method: POST,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }
      case 'gifts': {
        let url = buildApiUrl({
          path: '/Gifts',
          queryParams: {
            ...QUERY_TOKEN,
            filter: JSON.stringify({
              // podría optimizarce la consulta del comercio si se busca
              // en el arreglo de _stores si no está, pedirlo del serivodor y agregarlo al arreglo
              include: ['store', {
                relation: 'categories',
                scope: {
                  fields: ['id', 'name']
                }
              }]
            })
          }
        });
        let data = fetch(url);
        promise = promise.then(() => data);
        break;
      }
      case 'editGift': {
        let url = buildApiUrl({ path: '/Gifts/' + data.id });
        promise = promise.then(() => fetch(url, {
          method: PATCH,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }

      case 'patchVideo': {
        let url = buildApiUrl({ path: '/users/' + data.id });
        promise = promise.then(() => fetch(url, {
          method: PATCH,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }
      case 'report': {
        let url = buildApiUrl({ path: '/Tickets' });
        promise = promise.then(() => fetch(url, {
          method: POST,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }
      case 'stores': {
        let url = buildApiUrl({
          path: '/Stores', queryParams: {
            ...QUERY_TOKEN,
            filter: JSON.stringify({
              where: {
                isDeleted: false
              },
              include: 'companyStores'
            })
          }
        });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'certs': {
        let url = buildApiUrl({
          path: '/Certificates' + (environment.local ? '/index' : ''),
          queryParams: {
            ...QUERY_TOKEN,
            filter: JSON.stringify({
              where: {
                userId: this.user.id
              },
              include: 'category'
            })
          }
        });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'assistances': {
        let url = buildApiUrl({
          path: '/employee_events' + (environment.local ? '/index' : ''),
          queryParams: {
            ...QUERY_TOKEN,
            filter: JSON.stringify({
              where: {
                userId: this._user.id
              },
              include: [{
                relation: 'event',
                scope: {
                  include: 'campus'
                }
              }, 'user']
            })
          }
        });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'editAssistance': {
        console.log(data);

        let url = buildApiUrl({ path: '/employee_events/' + data.id });
        promise = promise.then(() => fetch(url, {
          method: PATCH,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }
      case 'downloadCert': {
        let url = buildApiUrl({ path: '/Certificates/' + data.certId + '/download' });
        promise = promise.then(() => window.open(url, '_blank'))
          .then(() => new Response(JSON.stringify({ done: true })));
        break;
      }
      case 'downloadCerts': {
        let url = buildApiUrl({ path: '/Certificates/download' });
        promise = promise.then(() => window.open(url, '_blank'))
          .then(() => new Response(JSON.stringify({ done: true })));
        break;
      }
      case 'sendNewGifts': {
        let url = buildApiUrl({ path: '/Gifts' });
        promise = promise.then(() => fetch(url, {
          method: POST,
          headers: HEADERS,
          body: BODY
        }))
          .then(async (r) => {
            console.log(this.balance);
            
            let payload = await tryResponse(r);
            if (payload && payload.error) throw payload.error;
            console.log(this._gifts);
            this.balance = +this.balance - this._gifts.reduce((t, g) => (t + +g.value), 0);
            this._gifts = [];
            console.log(this.balance);
            return payload;
          });
        break;
      }
      case 'editSchedule': {
        let url = buildApiUrl({ path: '/CertSchedules/' + data.id });
        promise = promise.then(() => fetch(url, {
          method: PATCH,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }
      case 'getGift': {
        let url = buildApiUrl({
          path: '/Gifts/' + data.id, queryParams: {
            // ...QUERY_TOKEN,
            filter: JSON.stringify({
              include: ['store', 'employee']
            })
          }
        });
        promise = promise.then(() => fetch(url, {
          method: GET,
          headers: HEADERS
        }));
        break;
      }

      case 'getCampus': {
        let url = buildApiUrl({ path: '/Campuses/' + data.id });
        promise = promise.then(() => fetch(url, {
          method: GET,
          headers: HEADERS
        }));
        break;
      }

      case 'getCP': {
        let url = buildApiUrl({
          path: '/codigo_postals', queryParams: {
            // ...QUERY_TOKEN,
            filter: JSON.stringify({
              where: { cp: this._codigopostal.cp }
            })
          }
        });
        promise = promise.then(() => fetch(url, {
          method: GET,
          headers: HEADERS
        }));
        break;
      }

      case 'cancelShedule': {
        let url = buildApiUrl({ path: '/CertSchedules/' + data.id });
        promise = promise.then(() => fetch(url, {
          method: DELETE,
          headers: HEADERS
        }));
        break;
      }
      case 'scheduleReport': {
        let url = buildApiUrl({ path: '/CertSchedules/report' });
        promise = promise.then(() => window.open(url, '_blank'))
          .then(() => new Response(JSON.stringify({ done: true })));
        break;
      }
      case 'sendSchedule': {
        let url = buildApiUrl({ path: '/CertSchedules' });
        promise = promise.then(() => fetch(url, {
          method: POST,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }
      case 'schedule': {
        let url = buildApiUrl({ path: '/CertSchedules' });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'resetPass': {
        let url = buildApiUrl({ path: '/users/reset' });
        promise = promise.then(() => fetch(url, {
          method: POST,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }
      case 'resetPass2': {
        let url = buildApiUrl({ path: '/users/reset-password' });
        promise = promise.then(() => fetch(url, {
          method: POST,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }
      case 'changeCert': {
        let url = buildApiUrl({ path: '/users/change-certificate' });
        promise = promise.then(() => fetch(url, {
          method: POST,
          headers: HEADERS,
          body: BODY
        }));
        break;
      }
      case 'certsInBalance': {
        let url = buildApiUrl({
          path: '/Certificates' + (environment.local ? '/index' : ''),
          queryParams: {
            ...QUERY_TOKEN,
            filter: JSON.stringify({
              where: {
                status: 'inBalance',
                userId: this.user.id
              },
              include: [{
                relation: 'category',
                scope: {
                  fields: ['id', 'name']
                }
              }]
            })
          }
        });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'certsAvailable': {
        let url = buildApiUrl({
          path: '/Certificates' + (environment.local ? '/index' : ''),
          queryParams: {
            ...QUERY_TOKEN,
            filter: JSON.stringify({
              where: {
                status: 'available',
                userId: this.user.id
              }
            })
          }
        });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'faqs': {
        let url = buildApiUrl({ path: '/Faqs' });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'verifyToken': {

        let url = buildApiUrlAux({ path: 'users/verifyToken' });

        promise = promise.then(() => fetch(url, {
          method: POST,
          headers: HEADERS,
          body: BODY
        }))
          .then(async (r) => {
            let payload = await tryResponse(r);
            if (payload && payload.error) throw payload.error;
            return payload;
          });
        break;
      }
      case 'certCategories': {
        let url = buildApiUrl({ path: '/CertificateCategories' });
        promise = promise.then(() => fetch(url));
        break;
      }

      case 'certCategories2': {
        let url = buildApiUrl({
          path: '/CertificateCategories', queryParams: {
            ...QUERY_TOKEN,
            filter: JSON.stringify({
              where: {
                companyId: this.user.companyId
              }
            })
          }
        });
        promise = promise.then(() => fetch(url));
        break;
      }

      case 'certTemplates': {
        let url = buildApiUrl({ path: '/CertTemplates' });
        promise = promise.then(() => fetch(url));
        break;
      }
      case 'notifications': {
        let month = moment().month();
        let year = moment().month();
        let gDate = moment({ day: 1, month }).toDate();
        let lDate = moment({
          day: 1,
          month: month === 11 ? 0 : month + 1,
          year: month === 11 ? year + 1 : year
        }).toDate();

        let url = buildApiUrl({
          path: '/ImportantDates',
          queryParams: {
            ...QUERY_TOKEN,
            filter: JSON.stringify({
              where: {
                date: { lt: lDate, gt: gDate }
              }
            })
          }
        });
        promise = promise.then(() => fetch(url));
        break;
      }
      default: {
        promise = promise.then(() => {
          throw {
            code: "FRONT_INVALID_SYNC_OPTION",
            name: "Error: " + type,
            message: "Opción invalida para sincronizar con servidor"
          }
        });
        break;
      }
    }
    return promise
      .then(async (r) => {
        let payload: any = r;
        if (r instanceof Response)
          payload = await tryResponse(r);
        if (payload && payload.error) throw payload.error;
        return payload;
      })
      .catch(e => {
        e.service = type;
        this.processError(e);
        return false;
      });
  }

  processError(error) {
    let message;
    switch (error.code) {
      case 'LOGIN_FAILED':
        message = 'Correo electrónico o contraseña incorrecta.';
        break;
      case 'AUTHORIZATION_REQUIRED':
        message = 'No cuentas con permisos para acceder a esta información.';
        break;
      case 'INVALID_PASSWORD':
        message = 'La contraseña actual es incorrecta.';
        break;
      default:
        message = error.name || 'Error';
        console.error(error.service + ':', error);
    }
    this.snackBar.open(message, 'Ver detalles', { duration: 4000 })
      .onAction().subscribe(() => {
        this.openErrorDialog({
          title: error.name || 'Error',
          description: error.message || 'Error inesperado',
          object: error
        });
      });
  }

  set user(user: any | User) {
    if (!user) return;
    let birthdate = user.birthdate ? new Date(user.birthdate) : null;

    this._user = Object.assign({}, this._user, {
      id: user.id,
      name: user.name,
      email: user.email,
      birthdate,
      balance: user.balance,
      maritalStatus: user.maritalStatus,
      anniversary: user.anniversaryDate || {},
      birthdateCouple: user.birthdateCouple || {},
      parentType: user.parentType === 'false' ? false : user.parentType,
      childrenBirthdates: user.childrenBirthdates || [],
      dateAdmission: user.dateAdmission,
      payrollNumber: user.payrollNumber,
      emailVerified: user.emailVerified,
      registered: user.registered,
      companyId: user.companyId,
      country: user.country,
      years: user.years,
      campusId: user.campusId
    });

    this.company = user.company;

    // important dates from profile
    let now = moment();
    let aniv = moment(this._user.anniversary);
    let bc = moment(this._user.birthdateCouple);
    let dates = [{
      title: 'Cumpleaños',
      date: birthdate,
      left: moment(birthdate).year(now.year()) >= now ? moment(birthdate).year(now.year()).diff(now, 'days') : moment(birthdate).year(now.year() + 1).diff(now, 'days'),
    },
    {
      title: 'Fecha de Ingreso',
      date: moment(user.dateAdmission).toDate(),
      left: moment(user.dateAdmission).year(now.year()) >= now ? moment(user.dateAdmission).year(now.year()).diff(now, 'days') : moment(user.dateAdmission).year(now.year() + 1).diff(now, 'days')
    }];
    if (this._user.maritalStatus === 'married') {
      dates.push({
        title: 'Aniversario',
        date: aniv.toDate(),
        left: aniv.diff(now, 'days') >= 0 ? aniv.diff(now, 'days') : moment(aniv).year(now.year() + 1).diff(now, 'days')
      });
      dates.push({
        title: 'Cumpleaños de Pareja',
        date: bc.toDate(),
        left: bc.diff(now, 'days') >= 0 ? bc.diff(now, 'days') : moment(bc).year(now.year() + 1).diff(now, 'days')
      });
    }
    if (user.childrenBirthdates) {
      dates.push(...user.childrenBirthdates.map((cbs, i) => ({
        title: 'Cumpleaños de hijo ' + (i + 1),
        date: moment(cbs),
        left: moment(cbs).diff(now, 'days') >= 0 ? moment(cbs).diff(now, 'days') : moment(cbs).year(now.year() + 1).diff(now, 'days')
      })));
    }
    let dates2 = dates.sort((a, b) => a.left - b.left).filter(d => d.left >= 0);
    this._dates = dates2;
  }

  get user() {
    return this._user;
  }

  async login({ username, password, rememberme }) {
    //console.log(username, password);

    let response = await this.apiSynchronize('login', { email: username, password });
    if (!response) return false;
    this._token = <string>response.id;
    this.user = response.user;
    if (rememberme) {
      localStorage.setItem('token', response.id);
      localStorage.setItem('userId', response.userId);
    } else {
      sessionStorage.setItem('token', response.id);
      sessionStorage.setItem('userId', response.userId);
    }

    const assistances = await this.apiSynchronize('assistances');
    if(assistances && assistances.length) this.assistances = assistances;

    return this._token;
  }

  async register(form) {
    let user = {
      birthdate: moment(form.birthdate).toISOString(),
      maritalStatus: form.maritalStatus,
      anniversaryDate: form.anniversary,
      birthdateCouple: form.birthdateCouple,
      parentType: form.parentType,
      numberChildren: form.childrenBirthdates.length,
      childrenBirthdates: form.childrenBirthdates,
      newPassword: form.password,
      registered: true,
      emailVerified: true,
      country: form.country,
      companyId: this.user.companyId,
      campusId: form.campusId
    };

    let resp = await this.apiSynchronize('register', user);
    Object.assign(this.user, user);    
    if (!resp) {
      return false;
    }
    let assistances = await this.apiSynchronize('assistances');
    if (assistances && assistances.length) return this.router.navigate(['/confirmacion']);
    else return this.router.navigate(['/canjear-certificados']);
    
    // if ([20, 22, 23, 24, 25, 28, 30, 31,35].includes(user.companyId)) {
    //   this.router.navigate(['/canjear-certificados']);
    //   return true;
    // }
    // else {
    //   this.router.navigate(['/confirmacion']);
    //   return true;
    // }
  }

  async logout() {
    await this.apiSynchronize('logout');
    this._token = false;
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    localStorage.removeItem('userId');
    sessionStorage.removeItem('userId');
    this.router.navigate(['/acceso']);
    return true;
  }

  set faqs(faqs) {
    this._faqs = faqs.map(f => ({
      id: f.id,
      question: f.question,
      answer: f.answer,
      category: f.category
    }));
  }

  get faqs() {
    return this._faqs;
  }

  set settings(settings) {
    this._settings = {
      name: settings.name,
      logo: this.bucket(settings.logo),
      phone: settings.phone,
      office: settings.office,
      workingHours: settings.workingHours,
      email: settings.email,
      facebook: settings.facebook,
      linkedin: settings.linkedin,
      instagram: settings.instagram
    };
  }

  get settings() {
    return this._settings;
  }

  set schedule(schedule) {
    this._schedule = schedule.map(s => ({
      id: s.id,
      userId: s.userId,
      user: s.user,
      groupId: s.groupId,
      group: s.group,
      img: s.img,
      createdAt: new Date(s.createdAt),
      date: new Date(s.date),
      value: s.value,
      certificateCategoryId: s.certificateCategoryId,
      category: s.category,
      description: s.description,
    }));
  }

  get schedule() {
    return this._schedule;
  }

  set notifications(notifs) {
    this._notifications = notifs.map(n => ({
      title: n.title,
      date: n.date,
      left: n.left
    }));
  }

  get notifications() {
    return this._notifications;
  }
}
