import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  store;

  constructor(private apiService: ApiService, private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      let storeId = parseInt(params['id']);
      this.store = this.apiService.stores.find(s => s.id === storeId);
    });
  }

  ngOnInit() {
  }

}
