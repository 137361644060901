import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-dialog-video',
  templateUrl: './dialog-video.component.html',
  styleUrls: ['./dialog-video.component.scss']
})
export class DialogVideoComponent implements OnInit {

  static box = {
    width: '90%',
    maxWidth: '400px',
    maxHeight: '80vh'
  };

  noVideo = false;

  constructor(
    private dialogRef: MatDialogRef<DialogVideoComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private apiService: ApiService
    ) { dialogRef.disableClose = true; }

  ngOnInit() {}


  async close() {
    if(this.noVideo == true){
      await this.apiService.apiSynchronize('patchVideo', {id: this.data.id, video: true});
      this.dialogRef.close();
    }
    else {this.dialogRef.close()}
  }

}
