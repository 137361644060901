import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-report-dialog',
  templateUrl: './report-dialog.component.html',
  styleUrls: ['./report-dialog.component.scss']
})
export class ReportDialogComponent implements OnInit {

  static box = {
    width: '90%',
    maxWidth: '400px',
    maxHeight: '80vh'
  };

  constructor(private dialogRef: MatDialogRef<ReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data) { }

  ngOnInit() {
  }

  sended() {
    this.dialogRef.close();
  }

  closeDialog(){
    this.dialogRef.close()
  }

}
