import { Component, OnInit, HostListener, AfterViewInit, Input } from '@angular/core';

@Component({
  selector: '[breakpoints]',
  templateUrl: './breakpoints.component.html',
  styleUrls: ['./breakpoints.component.scss']
})
export class BreakpointsComponent implements OnInit, AfterViewInit {

  breakpointsNames = ['xs', 'sm', 'md', 'lg', 'xl'];
  breakpoints = [];
  size = 0;
  br;

  constructor() { }

  ngOnInit() {
    let computed = getComputedStyle(document.documentElement)
    this.breakpoints = this.breakpointsNames.map(br =>
      ({
        [br]:
          parseInt(computed.getPropertyValue('--breakpoint-' + br).replace('px', ''))
      }));
  }

  @HostListener("window:resize", [])
  onResize() {
    this.detectScreenSize();
  }

  ngAfterViewInit() {
    this.detectScreenSize();
  }

  detectScreenSize() {
    this.size = window.innerWidth;
    this.br = this.breakpointsNames.find(br => this.breakpoints[br] > this.size);
  }


}
