import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import * as moment from "moment";
import { ApiService } from 'src/app/services/api.service';
import { CalendarDialogComponent } from 'src/app/components/calendar-dialog/calendar-dialog.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ReportDialogComponent } from 'src/app/components/report-dialog/report-dialog.component';
import { ActivatedRoute, Router } from '@angular/router'
import { Campus, CampusApi, Question, ResponseApi } from 'src/app/services/shared/sdk';

moment.locale('es');

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  loading = false;
  description = 'Revisa tus datos, asegúrate de que tu nombre esté completo y aparezca en el orden correcto (nombres y apellidos). Si hay algún error da clic en “Reportar un error”.';
  states = [
    'Aguascalientes',
    'Baja California',
    'Baja California Sur',
    'Campeche',
    'Coahuila',
    'Colima',
    'Chiapas',
    'Chihuahua',
    'Ciudad de México',
    'Durango',
    'Guanajuato',
    'Guerrero',
    'Hidalgo',
    'Jalisco',
    'Estado de México',
    'Michoacán',
    'Morelos',
    'Nayarit',
    'Nuevo León',
    'Oaxaca',
    'Puebla',
    'Querétaro',
    'Quintana Roo',
    'San Luis Potosí',
    'Sinaloa',
    'Sonora',
    'Tabasco',
    'Tamaulipas',
    'Tlaxcala',
    'Veracruz',
    'Yucatán',
    'Zacatecas'
  ];

  /*registerForm = new FormGroup({
    birthdate: new FormControl(this.apiService.user.birthdate, [Validators.required]),
    maritalStatus: new FormControl(this.apiService.user.maritalStatus || 'married', [Validators.required]),
    anniversary: new FormGroup({
      day: new FormControl(
        this.apiService.user.anniversary.day, [Validators.min(1), Validators.max(31)]
      ),
      month: new FormControl(this.apiService.user.anniversary.month)
    }),
    birthdateCouple: new FormGroup({
      day: new FormControl(
        this.apiService.user.birthdateCouple.day, [Validators.min(1), Validators.max(31)]
      ),
      month: new FormControl(this.apiService.user.birthdateCouple.month)
    }),
    parentType: new FormControl(this.apiService.user.parentType || false),
    childrenNumber: new FormControl(this.apiService.user.childrenBirthdates.length || null),
    childrenBirthdates: new FormArray([]),
    password: new FormControl(this.apiService.user.password, [Validators.required]),
    passValid: new FormControl(false, [Validators.requiredTrue]),
    terms: new FormControl(false, [Validators.requiredTrue]),
    privacy: new FormControl(false, [Validators.requiredTrue]),
    country: new FormControl(this.apiService.user.country, [Validators.required])
  });*/

  registerForm = new FormGroup({
    birthdate: new FormControl(this.apiService.user.birthdate),
    maritalStatus: new FormControl(this.apiService.user.maritalStatus || null),
    anniversary: new FormGroup({
      day: new FormControl(
        this.apiService.user.anniversary.day
      ),
      month: new FormControl(this.apiService.user.anniversary.month)
    }),
    birthdateCouple: new FormGroup({
      day: new FormControl(
        this.apiService.user.birthdateCouple.day
      ),
      month: new FormControl(this.apiService.user.birthdateCouple.month)
    }),
    parentType: new FormControl(this.apiService.user.parentType || false),
    childrenNumber: new FormControl(this.apiService.user.childrenBirthdates.length || null),
    childrenBirthdates: new FormArray([]),
    password: new FormControl(this.apiService.user.password),
    passValid: new FormControl(false),
    terms: new FormControl(false),
    privacy: new FormControl(false, [Validators.requiredTrue]),
    country: new FormControl(this.apiService.user.country, [Validators.required]),
    userCampus: new FormControl('', []),
    campusId: new FormControl(this.apiService.user.campusId, [])
  });

  months = moment.months();
  UserCampus: string;
  UserCompany;
  questionary: Question[]
  questionaryResp: any = {}
  questionaryValid: boolean = false;
  campuses: Campus[] = [];


  constructor(
    private apiService: ApiService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private responseApi: ResponseApi,
    private _snackBar: MatSnackBar,
    private _campus: CampusApi
  ) { }

  async ngOnInit() {
    let user = await this.apiService.apiSynchronize('user');
    this.UserCompany = user.company.name;
    if (user.isTec) this.UserCompany = 'Tecnológico de Monterrey';
    if (user.isSorteosTec) this.UserCompany = 'Sorteos TEC';
    if (user.isTecMilenio) this.UserCompany = 'Tecmilenio';
    if (user.isTecSalud) this.UserCompany = 'Tec Salud';
    if (user.isTecSec) this.UserCompany = 'Secundarias';

    this.questionary = user.company.questions
    if (this.questionary.length <= 0) this.questionaryValid = true
    if (user.campusId) {
      let campus = await this.apiService.apiSynchronize('getCampus', { id: user.campusId });
      this.UserCampus = campus.name;
      const campusInput = this.registerForm.get('userCampus') as FormControl;
      campusInput.setValue(campus.name);
      campusInput.updateValueAndValidity();
    }
    this.registerForm.get('parentType').valueChanges
      .subscribe(val => {
        if (val) {
          this.registerForm.get('childrenNumber')
            .setValidators([Validators.required, Validators.min(1)]);
        } else {
          this.registerForm.get('childrenNumber').clearValidators();
          this.registerForm.get('childrenNumber').setValue(null);
        }
      });
    this.registerForm.get('childrenNumber').valueChanges
      .subscribe(this.childrenNumberChange.bind(this));
    this.registerForm.get('maritalStatus').valueChanges
      .subscribe(val => {
        let fields = [
          this.registerForm.get('anniversary').get('day'),
          this.registerForm.get('anniversary').get('month'),
          this.registerForm.get('birthdateCouple').get('day'),
          this.registerForm.get('birthdateCouple').get('month'),
        ];
        let action = 'clearValidators';
        let params;
        if (val === 'married') {
          action = 'setValidators';
          params = [Validators.required];
        }
        fields.forEach(f => {
          f[action](params);
          f.updateValueAndValidity();
          if (val !== 'married') {
            f.setValue('');
          }
        });
      });
    this.childrenNumberChange();
    this.loading = true;
    let rCerts = await this.apiService.apiSynchronize('certsAvailable');
    this.loading = false
    if (!rCerts) return;
    this.apiService.certs = rCerts;
  }

  openCalendarDialog() {
    this.dialog.open(CalendarDialogComponent, {
      ...CalendarDialogComponent.box,
      data: {
        date: this.registerForm.get('birthdate').value
      }
    }).afterClosed().subscribe(({ date } = { date: null }) => {
      this.registerForm.get('birthdate').setValue(date);
    });
  }

  setQuestionaryValues(values) {
    this.questionaryValid = values.valid
    this.questionaryResp = values.answers
  }

  async saveQuestions(): Promise<boolean> {
    if (this.questionary.length <= 0) return true
    try {
      console.log('responses', this.questionaryResp);
      const companyId = this.apiService._company.id
      const userId = this.apiService._userId
      const newResponses = this.questionaryResp.map(res => ({ ...res, companyId, userId }))
      console.log('newResponses', newResponses);
      await this.responseApi.createMany(newResponses).toPromise()
      return true
    } catch (error) {
      console.log(error);

      this._snackBar.open('Error al guardar cuestionario', 'ERROR', {
        duration: 5000
      });
      return false
    }
  }

  async send() {
    // PATCH para que envie primero el cuestionario
    if (this.questionaryValid && await this.saveQuestions()) {
      this.loading = true;
      await this.apiService.register(this.registerForm.value);
      this.loading = false;
    } else {
      this._snackBar.open('Por favor completa el cuestionario', 'ERROR', {
        duration: 5000
      });
    }
  }

  showReportDialog(e) {
    e.preventDefault();
    this.dialog.open(ReportDialogComponent, ReportDialogComponent.box);
  }

  getBirthdate() {
    let date = this.registerForm.get('birthdate').value;
    return (date ? moment(date).format('DD/MM/YYYY') : 'Selecciona una fecha');
  }

  childrenNumberChange() {
    let control = <FormArray>this.registerForm.get('childrenBirthdates');
    control.clear();
    for (let i = 0; i < this.registerForm.get('childrenNumber').value; i++) {
      let day = null;
      let month = '';
      if (this.apiService.user.childrenBirthdates[i]) {
        day = this.apiService.user.childrenBirthdates[i].day;
        month = this.apiService.user.childrenBirthdates[i].month;
      }
      const fc = new FormGroup({
        day: new FormControl(day, [Validators.required]),
        month: new FormControl(month, [Validators.required])
      });
      control.push(fc);
    }
  }

  passIsEqual(e) {
    this.registerForm.get('passValid')
      .setValue(e.target.value === this.registerForm.get('password').value);
  }
}

@Component({
  selector: 'app-registerSSO',
  templateUrl: './registerSSO.component.html',
  styleUrls: ['./register.component.scss']
})

export class RegisterSSOComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService) { }

  async ngOnInit() {
    this.route.params.subscribe(async (params) => {
      let auxToken = {
        token: params['accessToken'],
        userId: params['id']
      }
      if (params) {
        let token = await this.apiService.apiSynchronize('verifyToken', auxToken);
        if (token) {
          sessionStorage.setItem('token', params['accessToken']);
          sessionStorage.setItem('userId', params['id']);
          this.apiService.user = token;
          this.router.navigate(['registro'])
        } else {
          this.router.navigate(['acceso'])

        }
      } else {
        this.router.navigate(['acceso']);
      }

    });
  }
}