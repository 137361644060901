import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class RegisteredService implements CanActivate {

  constructor(public router: Router, private apiService: ApiService) { }

  async canActivate(active: ActivatedRouteSnapshot, route: RouterStateSnapshot) {

    let goToRegister = route.url === '/registro';
    // to be sure the isRegistered variable is boolean use double !
    let isRegistered = !!this.apiService.isRegistered;

    if (isRegistered != goToRegister) {
      return true;
    }
    if (goToRegister && isRegistered) {
      return this.router.parseUrl('/');
    } else {
      return this.router.parseUrl('/registro');
    }
  }

}
