import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatCalendar } from '@angular/material';
import * as moment from 'moment';

@Component({
  selector: 'app-calendar-dialog',
  templateUrl: './calendar-dialog.component.html',
  styleUrls: ['./calendar-dialog.component.scss']
})
export class CalendarDialogComponent implements OnInit {
  static box = {
    width: '90%',
    maxWidth: '400px',
    maxHeight: '90vh'
  };

  @ViewChild('calendar', { static: false }) calendar: MatCalendar<Date>;
  startAt = new Date('1993-01-01');
  min;

  constructor(
    private dialogRef: MatDialogRef<CalendarDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data
  ) { }

  ngOnInit() {
    if (this.data.date) {
      this.startAt = this.data.date;
    }
    if (this.data.min) {
      this.min = this.data.min;
    }
  }

  prevMonthLabel() {
    return moment(this.startAt).subtract(1, 'month').format('MMM YYYY');
  }

  nextMonthLabel() {
    return moment(this.startAt).add(1, 'month').format('MMM YYYY');
  }

  prevMonth() {
    this.extraMonth(-1);
  }

  nextMonth() {
    this.extraMonth(1);
  }

  extraMonth(extra: number) {
    this.startAt = moment(this.startAt).add(extra, 'month').toDate();
    this.calendar._goToDateInView(this.startAt, 'month');
  }

  setDateView(date) {
    this.startAt = date;
  }

  close() {
    this.dialogRef.close(this.data);
  }

  get hideButtons() {
    if (!this.calendar) {
      return false;
    }
    return this.calendar.currentView === 'month';
  }

}
