import { Component, OnInit } from '@angular/core';
import * as moment from "moment";
import { registerLocaleData } from '@angular/common';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';

import localeEsMx from '@angular/common/locales/es-MX';
import { Gifts, GiftsApi } from 'src/app/services/shared/sdk';

moment.locale('es-MX');

registerLocaleData(localeEsMx, 'es-MX');

@Component({
  selector: 'app-cert-template',
  templateUrl: './cert-template.component.html',
  styleUrls: ['./cert-template.component.scss']
})
export class CertTemplateComponent implements OnInit {

  BASE_URL = 'https://apin.giftpedia.com.mx/';
  LOCAL_BASE_URL = 'https://apin.giftpedia.com.mx/';
  API_VERSION = 'api';
  CONTAINER_FOLDER = '/containers/cloudgiftmaster';

  idGift;
  gift: Gifts
  Certificado: any = {};

  date = new Date();
  date1;

  contact = [
    "help@demco.mx",
    "(045) (81) 10 24 61 68"
  ];

  openHours = [
    "Lunes a Viernes 9:30 AM - 6:30 PM"
  ];

  instrucciones = [
    "1. Ingresa a la página del comercio.",
    "2. Selecciona los artículos de tu preferencia.",
    "3. Al realizar el pago selecciona la opción de certificado de regalo, el número de folio y NIP que aparecen en tu certificado de regalo."
  ];
  restricciones = [
    "-Este certificdo no es reembolsable ni intercambiable por dinero en efectivo.",
    "-Valido únicamente para redención en el comercio seleccionado.",
    "-Envíos en página son gratis en compras superiores a los $100 pesos. Cambios en política de envíos sin previo aviso."
  ];

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private giftsApi: GiftsApi
  ) {
    this.route.params.subscribe(resp => {
      if (resp && resp.id) {
        this.idGift = resp.id;
      }
    })
  }

  async ngOnInit() {
    this.date1 = moment(this.date).add(3, 'months').format('DD/MMMM/YYYY');
    let gift = await this.apiService.apiSynchronize('getGift', { id: this.idGift });
    this.gift = gift;
    this.gift.downloadHistory = this.gift.downloadHistory || []
    console.log('this.gift', this.gift);

    this.Certificado = gift;
    this.Certificado.employeeName = gift.employee.name;
    this.Certificado.color = gift.store.color ? gift.store.color : "#000000";
    this.Certificado.logo = this.BASE_URL + '/' + this.API_VERSION + this.CONTAINER_FOLDER + '/download/' + gift.store.logo;
  }

  async Descargar() {
    document.getElementById('download').style.display = "none";
    document.getElementById('volver').style.display = "none";
    document.getElementById('head').style.display = "none";
    document.getElementById('footer').style.display = "none";
    alert('La siguiente ventana de sistema cuenta con ajustes por defecto para guardar o imprimir tu certificado. Realiza la siguiente configuración en la sección "Opciones/Más ajustes" para una visualización óptima: La opción de "encabezado y pie de página" desactivada, "Márgenes" en la opción de "Ninguno" y "Gráficos en segundo plano" habilitados.')
    window.print();
    const patchedGift = await this.giftsApi.patchAttributes(this.idGift, { downloadHistory: [...this.gift.downloadHistory, new Date()] }).toPromise();
    this.gift = patchedGift;
    this.Certificado.downloadHistory = this.gift.downloadHistory
    console.log('patchedGift', patchedGift);

    document.getElementById('download').style.display = "block";
    document.getElementById('volver').style.display = "block";
    document.getElementById('head').style.display = "block";
    document.getElementById('footer').style.display = "block";
  }

}
