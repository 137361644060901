import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Validators, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-reset-pass-form',
  templateUrl: './reset-pass-form.component.html',
  styleUrls: ['./reset-pass-form.component.scss']
})
export class ResetPassFormComponent implements OnInit {

  sended = false;
  loading = false;
  resetForm = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required])
  });

  constructor(private apiService: ApiService) { }

  ngOnInit() {
  }

  async reset(e) {
    this.loading = true;
    let resp = await this.apiService.apiSynchronize('resetPass', this.resetForm.value);
    this.loading = false;
    if (!resp) return;
    this.sended = true;
  }
}
