import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import * as moment from "moment";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  description = "Estamos felices de festejar contigo.";

  datesSliderConf = {
    slidesPerView: 1,
    spaceBetween: 20,
    watchOverflow: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    breakpoints: {
      1024: {
        slidesPerView: 3,
      },
      576: {
        slidesPerView: 2,
      }
    }
  }

  navLinks = [{
    title: 'Mis regalos actuales',
    label: 'Regalos',
    icon: 'gift',
    path: '/inicio'
  }, {
    title: 'Mi colección de certificados',
    label: 'Certificados',
    icon: 'cert',
    path: '/certificados'
  }, {
    title: 'Resumen de mi saldo',
    label: 'Mi saldo',
    icon: 'coins',
    path: '/saldo'
  }];

  tabIndex;
  tabTitle;

  constructor(private apiService: ApiService) { }

  ngOnInit() {
  }

  formatDate(date) {
    return moment(date).format('DD [de] MMMM');
  }

  dateColor(i) {
    let colors = ['green', 'orange', 'purple'];
    if (i > colors.length) {
      i = 0;
    }
    return colors[i];
  }

  activeTab(e, i) {
    if (e) {
      this.tabTitle = this.navLinks[i].title;
    }
    return e;
  }

}
