import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatDatepickerModule,
  MatDialogModule,
  MatMenuModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatTabsModule,
  MatIconModule
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  NgbCarouselModule,
  NgbCollapseModule,
  NgbDropdownModule,
  NgbProgressbarModule,
  NgbAccordionModule
} from '@ng-bootstrap/ng-bootstrap';
import { SwiperModule } from "ngx-swiper-wrapper";
//import { Bootstrap4FrameworkModule } from 'angular6-json-schema-form';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {
  BreakpointsComponent,
  CalendarDialogComponent,
  CardColorComponent,
  CertCardComponent,
  CertDialogComponent,
  CertFormComponent,
  CompanyInfoComponent,
  ErrorDialogComponent,
  ExchangeCertDialogComponent,
  FaqsComponent,
  FooterComponent,
  HeaderComponent,
  IconsComponent,
  InstructionsComponent,
  LoadingComponent,
  LoginFormComponent,
  PageComponent,
  ReportDialogComponent,
  ReportFormComponent,
  ResetPassFormComponent,
  StoreDialogComponent,
} from './components';

import {
  AccessComponent,
  BalanceComponent,
  CertsArchiveComponent,
  CheckoutComponent,
  ExchangeComponent,
  GiftsArchiveComponent,
  HelpComponent,
  HomeComponent,
  LoginComponent,
  PrivacyComponent,
  ProfileComponent,
  RegisterComponent,
  ResetPasswordComponent,
  SessionComponent,
  TermsComponent,
  RegisterSSOComponent
} from "./views";
import { ConfirmComponent } from './components/confirm/confirm.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { CertTemplateComponent } from './components/cert-template/cert-template.component';

import { NgxPaginationModule } from 'ngx-pagination';
import { ResetpassComponent } from './views/session/resetpass/resetpass.component';
import { TerminosComponent } from './views/access/terminos/terminos.component';
import { HelpVideosComponent } from './components/help-videos/help-videos.component';
import { DialogVideoComponent } from './components/dialog-video/dialog-video.component';
import { DiplomaComponent } from './components/diploma/diploma.component';
import { QuestionsFormComponent } from './components/questions-form/questions-form.component';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { SDKBrowserModule } from './services/shared/sdk';
//import { StoreDialog2Component } from './components/store-dialog2/store-dialog2.component';

registerLocaleData(es);

@NgModule({
  declarations: [
    AccessComponent,
    AppComponent,
    BalanceComponent,
    BreakpointsComponent,
    CalendarDialogComponent,
    CardColorComponent,
    CertCardComponent,
    CertDialogComponent,
    CertFormComponent,
    CertsArchiveComponent,
    CheckoutComponent,
    CompanyInfoComponent,
    ErrorDialogComponent,
    ExchangeCertDialogComponent,
    ExchangeComponent,
    FaqsComponent,
    FooterComponent,
    GiftsArchiveComponent,
    HeaderComponent,
    HelpComponent,
    HomeComponent,
    IconsComponent,
    InstructionsComponent,
    LoadingComponent,
    LoginComponent,
    LoginFormComponent,
    PageComponent,
    PrivacyComponent,
    ProfileComponent,
    RegisterComponent,
    ReportDialogComponent,
    ReportFormComponent,
    ResetPassFormComponent,
    ResetPasswordComponent,
    SessionComponent,
    StoreDialogComponent,
    TermsComponent,
    ConfirmComponent,
    ConfirmDialogComponent,
    CertTemplateComponent,
    ResetpassComponent,
    TerminosComponent,
    RegisterSSOComponent,
    HelpVideosComponent,
    DialogVideoComponent,
    DiplomaComponent,
    QuestionsFormComponent
    //StoreDialog2Component,
  ],
  imports: [
    SDKBrowserModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTabsModule,
    MatIconModule,
    NgbAccordionModule,
    NgbCarouselModule,
    NgbCollapseModule,
    NgbDropdownModule,
    NgbProgressbarModule,
    ReactiveFormsModule,
    SwiperModule,
    NgxPaginationModule,
    FormlyModule.forRoot({
      extras: {
        lazyRender: true
      }
    }),
    FormlyMaterialModule,
    FormlyMatDatepickerModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-MX' },
  ],
  entryComponents: [
    CalendarDialogComponent,
    CertDialogComponent,
    ErrorDialogComponent,
    ExchangeCertDialogComponent,
    ReportDialogComponent,
    StoreDialogComponent,
    ConfirmDialogComponent,
    DialogVideoComponent
    //StoreDialog2Component
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
