import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {

  static box = {
    width: '70%',
    height: 'auto',
    minWidth: '310px',
    minHeight: '400px',
    maxHeight: '880px'
  };

  constructor(
    private dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private error: any
  ) {

  }

  ngOnInit() {

  }

  get sbox() {
    return ErrorDialogComponent.box;
  }

  close() {
    this.dialogRef.close();
  }

}
