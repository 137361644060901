import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-store-dialog',
  templateUrl: './store-dialog.component.html',
  styleUrls: ['./store-dialog.component.scss']
})
export class StoreDialogComponent implements OnInit {

  BASE_URL = 'https://apin.giftpedia.com.mx/';
  API_VERSION = 'api';
  CONTAINER_FOLDER = '/containers/cloudgiftmaster';
  giftURL;
  static box = {
    width: '90%',
    maxWidth: '400px',
    maxHeight: '80vh'
  };

  terminos = false;
  canChange = true;

  constructor(
    private apiService: ApiService,
    private dialogRef: MatDialogRef<StoreDialogComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private data) {
    this.giftURL = this.BASE_URL + this.API_VERSION + this.CONTAINER_FOLDER + '/download/' + this.data.store.termsExcerpt;
  }

  ngOnInit() {
    this.canChange = this.data.canChange;
  }

  close() {
    if (this.data.useToSelect) {
      let storeId = this.data.store.id;
      this.apiService.storeSetActive(storeId);
      let store = this.apiService.getStoreById(storeId);
      this.apiService.setGift({
        storeId,
        value: store.values[0],
        shippingInfo: store.productType === 0 ? {} : null,
        ticketInfo: store.isTicket ? {} : null
      });
      const gift = {
        storeId,
        value: store.values[0],
        shippingInfo: store.productType === 0 ? {} : null,
        ticketInfo: store.isTicket ? {} : null,
        store
      }
      return this.dialogRef.close({ gift })
    }
    this.dialogRef.close({});
  }

  noTerms() {
    this.snackBar.open('SIN ARCHIVO DE TÉRMINOS', '¡ALERTA!', {
      duration: 4000
    });
  }

  closeDialog() {
    this.dialogRef.close()
  }

}
