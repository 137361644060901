import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-color',
  templateUrl: './card-color.component.html',
  styleUrls: ['./card-color.component.scss']
})
export class CardColorComponent implements OnInit {

  @Input() title;
  @Input() subtitle;
  @Input() color;

  constructor() { }

  ngOnInit() {
  }

}
