import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog } from '@angular/material';
import { CertDialogComponent } from 'src/app/components';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-certs-archive',
  templateUrl: './certs-archive.component.html',
  styleUrls: ['./certs-archive.component.scss']
})
export class CertsArchiveComponent implements OnInit {
  loading: boolean = false;
  //description = '¡Puedes coleccionar tus certificados!';
  description = '';
  categoryId: number = null;
  Certs;
  User: any = {};
  p: number = 1;
  nowDate = moment();
  availableCategories = [];
  companyId: number;

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog,
    private router: Router
  ) { }

  async ngOnInit() {
    this.companyId = this.apiService.company.id;
    if (!this.apiService.certsArchive.length) {
      this.loading = true;
      let certs = await this.apiService.apiSynchronize('certs');
      this.loading = false;
      if (!certs) return;
      this.availableCategories = certs.reduce((categories, certificate) => {
        if (!categories.some(c => c.id == certificate.categoryId)) categories.push(certificate.category);
        return categories;
      }, []);
      this.apiService.certsArchive = certs;
    }

    if (!this.apiService.certCategories.length) {
      this.loading = true;
      let certCategories = await this.apiService.apiSynchronize('certCategories2');
      this.loading = false;
      if (!certCategories) return;
      this.apiService.certCategories = certCategories.filter((cat: any) => {
        return this.certs.map((c: any) => c.categoryId).includes(cat.id)
      })
    }

  }

  async ngAfterViewInit() {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.User = await this.apiService.apiSynchronize('user');
    /* if (this.User.isLealtad) {
      document.getElementById('btnDiploma').removeAttribute('disabled');
    } */
  }

  get certs() {
    return this.apiService.certsArchive
      .filter(c => this.categoryId ? (this.categoryId == c.categoryId) : true);
  }

  openCertDialog(cert) {
    this.dialog.open(CertDialogComponent, {
      ...CertDialogComponent.box,
      data: { ...cert, value: cert.value2 }
    })
  }

  async downloadCert(cert) {
    cert.loading = true;
    await this.apiService.apiSynchronize('downloadCert', { certId: cert.id });
    cert.loading = false;
  }

  async downloadCerts() {
    this.loading = true;
    await this.apiService.apiSynchronize('downloadCerts');
    this.loading = false;
  }

  download() {
    this.router.navigate(['/diploma']);
  }

}
