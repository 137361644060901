import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  loading = false;
  title;
  balance = false;
  helpBalance = false;
  hasEvent = false;

  BASE_URL = 'https://apin.giftpedia.com.mx/';
  API_VERSION = 'api';
  CONTAINER_FOLDER = '/containers/cloudgiftmaster';
  company;
  Url_Logo = '';
  Url_Banner;

  constructor(private apiService: ApiService, private router: Router) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.onActivateRoute(e);
      }
    });
  }

  async ngOnInit() {
    let assistances = await this.apiService.apiSynchronize('assistances');
    if (assistances.length) {
      this.hasEvent = true;
    }
    this.company = await this.apiService.apiSynchronize('company');
    this.title = this.company.phrase;
    this.Url_Logo = this.BASE_URL + '/' + this.API_VERSION + this.CONTAINER_FOLDER + '/download/' + this.company.logoWhite;
    this.Url_Banner = 'url(' + this.BASE_URL + '/' + this.API_VERSION + this.CONTAINER_FOLDER + '/download/' + this.company.banner + ')';
  }

  onActivateRoute(e) {
    let balanceRoutes = [
      '^/$',
      '/inicio',
      '/certificados',
      '/saldo',
      '/canjear-certificados',
      '/perfil'
    ];
    this.balance = new RegExp(balanceRoutes.join("|")).test(e.url);
    this.helpBalance = false;
    switch (true) {
      case /\/canjear-certificados\/confirmacion/.test(e.url):
        this.title = 'Resumen de tu canje';
        break;
      case /\/canjear-certificados/.test(e.url):
        this.helpBalance = true;
        this.title = 'Canjea tus certificados';
        break;
      case /\/ayuda/.test(e.url):
        this.title = 'Estamos para ayudar';
        break;
      case /\/perfil/.test(e.url):
        this.title = 'Perfil del usuario';
        break;
      case /\/terminos/.test(e.url):
        this.title = 'Términos y condiciones';
        break;
      case /\/aviso-privacidad/.test(e.url):
        this.title = 'Aviso de privacidad';
        break;
      default:
        this.title = 'Inicio'
        break;
    }

  }
  async logout() {
    this.loading = true;
    await this.apiService.logout();
    this.loading = false;
  }
}
