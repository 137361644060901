import { Component, OnInit, Input } from '@angular/core';

interface routeLink {
  title?: string;
  label: string;
  icon: string;
  path: (string | number)[];
};

@Component({
  selector: 'app-router-tabs',
  templateUrl: './router-tabs.component.html',
  styleUrls: ['./router-tabs.component.scss']
})
export class RouterTabsComponent implements OnInit {

  @Input() tabTitle: string;
  @Input() navLinks: routeLink[] = [];

  constructor() { }

  ngOnInit() {
  }

  activeTab(e, i) {
    if (e) {
      this.tabTitle = this.navLinks[i].title || this.tabTitle;
    }
    return e;
  }

}
