import { AccessComponent } from './access/access.component';
import { BalanceComponent } from './session/balance/balance.component';
import { CertsArchiveComponent } from './session/certs-archive/certs-archive.component';
import { CheckoutComponent } from './session/checkout/checkout.component';
import { ExchangeComponent } from './session/exchange/exchange.component';
import { GiftsArchiveComponent } from './session/gifts-archive/gifts-archive.component';
import { HelpComponent } from './session/help/help.component';
import { HomeComponent } from './session/home/home.component';
import { LoginComponent } from './access/login/login.component';
import { PrivacyComponent } from './session/privacy/privacy.component';
import { ProfileComponent } from './session/profile/profile.component';
import { RegisterComponent } from './session/register/register.component';
import { ResetPasswordComponent } from './access/reset-password/reset-password.component';
import { SessionComponent } from './session/session.component';
import { TermsComponent } from './session/terms/terms.component';
import { ConfirmComponent } from '../components/confirm/confirm.component';
import { RegisterSSOComponent } from './session/register/register.component';

export {
  AccessComponent,
  BalanceComponent,
  CertsArchiveComponent,
  CheckoutComponent,
  ExchangeComponent,
  GiftsArchiveComponent,
  HelpComponent,
  HomeComponent,
  LoginComponent,
  PrivacyComponent,
  ProfileComponent,
  RegisterComponent,
  ResetPasswordComponent,
  SessionComponent,
  TermsComponent,
  ConfirmComponent,
  RegisterSSOComponent
};
