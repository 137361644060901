import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService implements CanActivate {

  constructor(public router: Router, private apiService: ApiService) {

  }

  async canActivate(active: ActivatedRouteSnapshot, route: RouterStateSnapshot) {

    if (!this.apiService.user && this.apiService.token) {
      let user = await this.apiService.apiSynchronize('user');
      if (!user) {
        this.apiService.logout();
        return false;
      }
      this.apiService.user = user;
      let settings = await this.apiService.apiSynchronize('settings');
      if (settings) {
        this.apiService.settings = settings;
      }
    }

    if (!this.apiService.token) {
      this.router.navigate(['/acceso']);
    }
    return !!this.apiService.token;
  }
}
