import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-page',
  templateUrl: './page-admin.component.html',
  styleUrls: ['./page-admin.component.scss']
})
export class PageAdminComponent {

  pcHeight = '100vh';
  timeout;
  closeMenu = true;
  routes = [{
    routerLink: ['/inicio'],
    label: 'Inicio',
    icon: 'home'
  },
  {
    routerLink: ['/programar-envio'],
    label: 'Certificados',
    icon: 'cert'
  },
  {
    routerLink: ['/categorias'],
    label: 'Categorías',
    icon: 'figures'
  },
  {
    routerLink: ['/envios-programados'],
    label: 'Programados',
    icon: 'mail'
  },
  {
    routerLink: ['/colaboradores'],
    label: 'Colaboradores',
    icon: 'people'
  },
  {
    routerLink: ['/historial'],
    label: 'Historial',
    icon: 'clock'
  }];

  constructor() { }

  ngOnInit() {
    this.calcHeight();
  }


  @HostListener("window:resize", [])
  onResize() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.calcHeight();
    }, 300);
  }

  calcHeight() {
    let header = document.querySelector('app-header>header');
    let height = header.clientHeight;
    this.pcHeight = `calc(100vh - ${height}px)`;
  }

  toggleMenu(e) {
    e.preventDefault();
    this.closeMenu = !this.closeMenu;
  }

}
