import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  loading = false;
  wellcome = 'Ingresa tu correo institucional y tu contraseña.<br><br>';

  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    rememberme: new FormControl(false)
  });
  constructor(private router: Router, private apiService: ApiService) { }

  ngOnInit() {
  }

  /*async login() {
    this.loading = true;
    let token = await this.apiService.login(this.loginForm.value);
    this.loading = false;
    if (token) {
      let assistances = await this.apiService.apiSynchronize('assistances');
      if (assistances.length && assistances[0].status == null){
        this.router.navigate(['confirmacion'])
      } else {
        this.router.navigate(['/']);
      }
      
    }
  }*/

  async login() {
    this.loading = true;
    let token = await this.apiService.login(this.loginForm.value);
    this.loading = false;
    if (token) {
      this.router.navigate(['registro']);
    } else {
        this.router.navigate(['/']);
    }
      
  }
}

