import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss']
})
export class SessionComponent implements OnInit {

  loading = false;

  constructor(private apiService: ApiService) { }

  async  ngOnInit() {
    if (!this.apiService.company) {
      this.loading = true;
      let company = await this.apiService.apiSynchronize('company');
      this.loading = false;
      if (!company) return;
      this.apiService.company = company;
    }
  }

}
