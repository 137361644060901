import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  loading: boolean = false;
  description = '¿Tienes alguna duda o problema<br> que no aparezca en las FAQ?<br>¡Contáctanos!';
  descriptionFaqs = 'Ayuda sobre la plataforma';

  constructor(private apiService: ApiService) { }

  async ngOnInit() {
    if (this.apiService.faqs.length) return;
    this.loading = true;
    let rFaqs = await this.apiService.apiSynchronize('faqs');
    this.loading = false;
    if (!rFaqs) return;
    this.apiService.faqs = rFaqs;
  }

}
