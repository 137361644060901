import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header-admin.component.html',
  styleUrls: ['./header-admin.component.scss']
})
export class HeaderAdminComponent implements OnInit {
  loading = false;
  constructor(private apiService: ApiService) { }

  ngOnInit() {
  }

  async logout() {
    this.loading = true;
    await this.apiService.logout();
    this.loading = false;
  }

}
