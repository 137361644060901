import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { ApiService } from 'src/app/services/api.service';
import { Question, QuestionApi } from 'src/app/services/shared/sdk';
import { isNullOrUndefined } from 'util';
import * as moment from 'moment';

@Component({
  selector: 'app-questions-form',
  templateUrl: './questions-form.component.html',
  styleUrls: ['./questions-form.component.scss']
})
export class QuestionsFormComponent implements OnChanges {
  @Input('questionary') questions: Question[]
  @Output('onFormChange') onFormChange: EventEmitter<any> = new EventEmitter<any>()
  loading = false;
  dialogQuestion = false;
  myQuestionary: FormGroup = new FormGroup({})
  fields: FormlyFieldConfig[] = []
  options: FormlyFormOptions = {};
  model = {};

  constructor(
    private questionApi: QuestionApi,
    private apiService: ApiService
  ) {
    this.myQuestionary.valueChanges.subscribe(values => this.formOnChanges(values))
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.fields = changes.questions.currentValue && changes.questions.currentValue.length ? await this.transformQuestionsToFormly(this.questions) : []

  }

  formOnChanges(values: Object) {
    let answers = []
    Object.keys(this.model).forEach(key => values[key] != null ? answers.push({
      questionId: key,
      value: moment(values[key], 'DD/MM/YYYY', true).isValid() ? moment(values[key]).format('DD/MM/YYYY') : values[key]
    }) : null)
    this.onFormChange.emit({ valid: this.myQuestionary.valid, answers })
  }

  async getQuestions(): Promise<void> {
    const questions: Question[] = await this.questionApi.find<Question>({ where: { isDeleted: false, companyId: this.apiService._company.id } }).toPromise()
    console.log(questions);
  }

  async transformQuestionsToFormly(questions: Question[]): Promise<FormlyFieldConfig[]> {
    let formlyQuestions = []
    for (const question of questions) {
      let { id, type, options } = question
      const newQuestionToForm: FormlyFieldConfig = {
        key: `${id}`,
        type,
        templateOptions: options[0],
      }
      formlyQuestions = formlyQuestions.concat(newQuestionToForm)
    }
    return formlyQuestions
  }

}
